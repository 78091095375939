app.controller('reportPLctrl', function ($rootScope, $scope, urlServices, $filter, toaster, _VAR_GLOBAL, modalFactory, modalService, $window) {
    $scope.params={};
    $scope.periods= {
        text:"Pilih Periode",
        isSelect:true
    }
    $scope.viewData=function(){
        console.log($scope.params);
        if ($scope.params && !$scope.params.start_month || !$scope.params.end_month ){
            return toaster.pop('error','Bulan','Pilih bulan')
        };

        urlServices.simpanData('api/report/finance/profitloss', $scope.params, function (g, s) {
            console.log(s);
            if (g) {
                if (g && g.err && g.err instanceof Array) {
                    $scope.errorMessage = g.err;
                    var elP = '';
                    for (var key in $scope.errorMessage) {
                        elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                    }
                    toaster.pop('error', 'Error Data', elP);
                } else {
                    toaster.pop('error', 'Error Data', g.err);
                }
            } else {
                $scope.dataList=s;
                $scope.periods={
                    text:"Periode : " + $filter('date')(new Date(parseInt($scope.params.year),parseInt($scope.params.start_month)-1,1), "MMMM") + ' - ' + $filter('date')(new Date(parseInt($scope.params.year),parseInt($scope.params.end_month)-1,1), "MMMM") + ' ' + $scope.params.year,
                    isSelect:false
                }

            }
            // $scope.dataCustomer=args.dt;
        })
        
    };
})