app
    .controller('manifestBaggingPrintCtrl', function ($rootScope,_API_URL, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        var bagId = urlServices.getUrlParameter('bag');
        $scope.master={};
        $scope.detail=[];
        $scope.vendor=[];
        $scope.options = {
            width: 1,
            height: 50,
            quite: 10,
            displayValue: false,
            font: "monospace",
            textAlign: "center",
            fontSize: 12,
            backgroundColor: "",
            lineColor: "#000"
        };
        $scope.string=bagId;
 
            
            if (bagId) {
                urlServices.ambilData('api/bagging/masterdetail/' + bagId, {}, function (e, d) {
                    console.log(d)
                    if (d) {
                        $scope.master=d.master;
                        $scope.detail=d.detail;
                    } else {
                        toaster.pop('error', 'error data', 'Data tidak bisa di proses' + e.err);
        
                    }
                    // $scope.dataCustomer=args.dt;
                })
                
            } else{
                return window.location=_API_URL+'manifestbagging';
            }
             
        
    });