app.factory('globalServices', function ($cookieStore, $state, $window, _API_URL,_CN_FORM, $localStorage, _VAR_GLOBAL, shareData, $uibModal) {
    var x =null;
    var t =null;
    
    var print = {

        awb: function (dt) {
            shareData.setDataDetails(dt);

            // $state.go('app.laporan.cetakawb',{
            //     data:dt.shipment_awb
            // });

            if (dt && dt.length > 0) {
                var qry = {
                    company_id: $localStorage.globals.companyHo,
                    shipment_awbm: {
                        $in: dt
                    }
                };
            } else {
                var qry = {
                    company_id:$localStorage.globals.companyHo,
                    shipment_awbm: {
                        $in: [dt.shipment_awbm]
                    }
                };
            }

            $window.open(_API_URL + 'report/awb?html=false&copies=3&template='+_CN_FORM+'&qry=' + JSON.stringify(qry) + '&t=' + t, 'awb','_blank');
        },

        awbpickup: function (dt) {
            shareData.setDataDetails(dt);

            // $state.go('app.laporan.cetakawb',{
            //     data:dt.shipment_awb
            // });

            if (dt && dt.length > 0) {
                var qry = {
                    company_id: $localStorage.globals.companyHo,
                    shipment_awbm: {
                        $in: dt
                    }
                };
            } else {
                var qry = {
                    company_id: $localStorage.globals.companyHo,
                    shipment_awbm: {
                        $in: [dt.shipment_awbm]
                    }
                };
            }

            $window.open(_API_URL + 'report/awbpickup? copies=3&template='+_CN_FORM+'&qry=' + JSON.stringify(qry) + '&t=' + t, 'awb','_blank');
            
        },
        tracing: function (dt) {
            console.log('tracing mdoule');
            shareData.setDataDetails(dt);
            console.log(dt);

            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'tracing.html',
                controller: function ($scope, shareData,urlServices, $uibModalInstance) {
                    //console.log(shareData.getDataDetails());
                    // init
                    var dt = shareData.getDataDetails();
                    $scope.dataShipment = {};
                    $scope.dtTrace = [];
                    urlServices.ambilData('api/outbound/tracing/' + dt.awb, {}, function (e, d) {
                        console.log('hasil outbound', d);
                        var dt = d.length > 0 ? d[0] : null;
                        
                        if (dt) {
                            $scope.dataShipment = dt;
                            $scope.dtTrace = dt.tb_shipment_histories;
                        } else {
                            return false;
                        }
                    });

                    $scope.close = function () {
                        $uibModalInstance.close();
                    };
                }

        
                
                
                 
            });
            // $uibModal.open({
            //     animation: true,
            //     keyboard: false,
            //     backdrop: 'static',
            //     templateUrl: 'views/modal/tracing.html',
            //     // controller: modalController,
            //     size: 'lg',
            //     resolve: {
            //         params: function () {
            //             return {};
            //         }
            //     },
          

            // $uibModal.open({
            //     templateUrl: 'views/modal/tracing.html',
            //     controller: function ($scope, shareData, $uibModalInstance) {
            //         //console.log(shareData.getDataDetails());
            //         // init
            //         var dt = shareData.getDataDetails();
            //         var qry = JSON.stringify({
            //             id: dt.id
            //         });
            //         $scope.dataShipment = {};
            //         $scope.dtTrace = [];
            //         $scope.bc = {
            //             "format": "EAN",
            //             "lineColor": "#000000",
            //             "width": 2,
            //             "height": 100,
            //             "displayValue": false,
            //             "font": "monospace",
            //             "textAlign": "center",
            //             "textPosition": "bottom",
            //             "textMargin": 2,
            //             "fontSize": 20,
            //             "background": "#ffffff",
            //             "margin": 10,
            //             "marginTop": 10,
            //             "marginBottom": 10,
            //             "marginLeft": 10,
            //             "marginRight": 10
            //         }
            //         urlServices.ambilData('api/outbound?qry=' + qry, {
            //             id: dt.id
            //         }, function (e, d) {
            //             var dt = d.length > 0 ? d[0] : null;
            //             //console.log('hasil outbound', dt);
            //             if (dt) {
            //                 $scope.dataShipment = dt;
            //                 $scope.dtTrace = dt.tb_shipment_histories;
            //             } else {
            //                 Notification({
            //                     message: 'Error mengambil data'
            //                 }, 'error');
            //             }
            //         });

            //         $scope.ok = function () {
            //             $uibModalInstance.close();
            //         };
            //     }
            // })
        },
        manifest: function (dt) {
            shareData.setDataDetails(dt);
            $uibModal.open({
                windowClass: 'awb-modal-window',
                templateUrl: 'views/modal/manifest.html',
                controller: function ($scope, $rootScope, shareData, $uibModalInstance, generatePdf) {
                    //console.log(shareData.getDataDetails());
                    //console.log($rootScope.globals);
                    // init
                    var dt = shareData.getDataDetails();
                    var qry = JSON.stringify({
                        id: dt.id
                    });
                    $scope.manifestHeader = dt;
                    $scope.defaultData = [];
                    $scope.dataShipment = {};

                    //console.log($scope.manifesHeader);
                    $scope.cetakPdf = function (id) {
                        // generatePdf(id,$scope.dataShipment.shipment_awb);
                    };
                    urlServices.ambilData('api/companybranch', {}, function (e, d) {
                        var dt = d.length > 0 ? d[0] : null;
                        if (dt) {
                            $scope.companybranch = dt;
                            //console.log($scope.companybranch)
                        } else {
                            $scope.companybranch = [];
                        }
                    });
                    urlServices.ambilData('api/manifestdetail?id=' + dt.manifest_id, {}, function (e, d) {
                        var dt = d;
                        //console.log('hasil outbound', dt);
                        if (dt) {
                            $scope.dataShipment = dt;

                        } else {
                            Notification({
                                message: 'Error mengambil data'
                            }, 'error');
                        }
                    });
                    $scope.ok = function () {
                        $uibModalInstance.close();
                    };


                }
            })
        }
    };

    return print;
})
