app.controller('userViewCtrl', function ($rootScope, $scope, $state, shareData, $filter, $stateParams, urlServices, modalFactory,_VAR_GLOBAL,toaster) {
    $scope.filter = '';
    $scope.groups = [];
    $scope.dataContact = {
        totalItems: 0,
        data: []
    }
    $scope.paginationOptions = {
        pageNumber: 1,
        pageSize: _VAR_GLOBAL.limit,
        sort: null
    };
    $scope.setUser=function(data){
        modalFactory.open('lg', 'modal.setuser.html', 'modalSetUserCtrl', {
            functionName: 'userList',
            params: data
        });
    };
    $scope.addContact = function (isNew) {
        
        $state.go('app.user.trx', {
            data: JSON.stringify({
                isNew: isNew
            })
        });
    };
    $scope.editContact = function (dt) {
        $state.go('app.user.trx', {
            data: JSON.stringify({
                isNew: false,
                id: dt.email
            })
        });
    };
     
    $scope.viewData = function (tipe, pg) {
        $scope.paginationOptions.pageNumber = pg;
        $scope.getData($scope.paginationOptions.pageSize, $scope.paginationOptions.pageNumber, $scope.filter);
    }
    $scope.getData = function (pageSize, page, searchText, qry) {
        var qry = qry;
        setTimeout(function () {
            var data;
            if (searchText) {
                var ft = searchText.toLowerCase();
                data = 'paging=true&term=' + ft + '&limit=' + pageSize + '&pg=' + page + '&qry=' + JSON.stringify(qry);


            } else {
                data = 'paging=true&limit=' + pageSize + '&pg=' + page + '&qry=' + JSON.stringify(qry);
            }

            urlServices.ambilData('api/userlist?' + data, {}, function (err, sukses) {
                if (err) {
                    toaster.pop('error', 'Error Data', err.err);
                    $scope.dataContact.totalItems = 0;
                    $scope.dataContact.data = []

                } else {
                    $scope.dataContact.totalItems = sukses.total;
                    $scope.dataContact.data = sukses;
                }
            })
        }, 100);
    };
    $scope.viewData=function(tipe){
        $scope.getData($scope.paginationOptions.pageSize, $scope.paginationOptions.pageNumber, $scope.filter,{contact_type:tipe});
    };
    $scope.viewData();
    
})