app
    .controller('poTrxCtrl', function ($rootScope, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };

        console.log(urlServices.getUrlParameter('pr'))

        var defaultJournal = {
            Satuan: "PCS",
            HargaSatuan: 0,
            Qty: 1,
            SubTotal: 0.00
        };
        $scope.detailJournal = [];
        $scope.data = {
            Tanggal: moment()._d,
            TanggalButuh: moment()._d,
            DiscPersen: 0,
            Disc: 0,
            SubTotalDisc: 0,
            NilaiPPN: 0,
            PPnPersen: 0,
            Total: 0
        };
        $scope.isEdit = false;

        $scope.detailData = {};
        $scope.getIndex = null;


        $scope.savePO = function () {
            modalService.showModal({}, {
                    closeButtonText: 'No',
                    actionButtonText: 'Yes',
                    headerText: 'New',
                    bodyText: 'Simpan Purchase Order?'
                })
                .then(function (result) {
                    if (result) {
                        console.log(new Date($scope.data.Tanggal));
                        if ($scope.data.Tanggal && $scope.data.Tanggal._d) {
                            console.log('with d');
                            $scope.data.Tanggal = $scope.data.Tanggal._d;
                        };
                        if ($scope.data.TanggalButuh && $scope.data.TanggalButuh._d) {
                            console.log('with d');
                            $scope.data.TanggalButuh = $scope.data.TanggalButuh._d;
                        };

                        console.log($scope.data);
                        console.log($scope.detailJournal);
                        $scope.hitung();
                        $scope.data.TotalQty=$filter('sumByKey')($scope.detailJournal, 'Qty')
                        $scope.data.detail = $scope.detailJournal;

                        if ($scope.isEdit){
                            urlServices.updateData('api/po', $scope.data, function (g, s) {
                                console.log(s);
                                console.log(g);
                                if (g) {
                                    if (g && g.err && g.err instanceof Array) {
                                        console.log('error');
                                        $scope.errorMessage = g.err;
                                        var elP = '';
                                        for (var key in $scope.errorMessage) {
                                            elP +=  $scope.errorMessage[key].msg;
                                        }
                                        console.log(elP)
                                        toaster.pop('error', 'error data', elP);
                
                
                                    } else {
                                        toaster.pop('error', 'error data', g.err);
                                        window.location=_API_URL+'purchasing?screen=prview';
                
                                    }
                                } else {
                                    
                                    toaster.pop('success', 'Success', 'Data disimpan');
                                    window.location=_API_URL+'purchasing?screen=prview';
                                    
                
                
                                }
                
                            })
                        }else{
                            urlServices.simpanData('api/po', $scope.data, function (g, s) {
                                console.log(s);
                                console.log(g);
                                if (g) {
                                    if (g && g.err && g.err instanceof Array) {
                                        console.log('error');
                                        $scope.errorMessage = g.err;
                                        var elP = '';
                                        for (var key in $scope.errorMessage) {
                                            elP += $scope.errorMessage[key].msg;
                                        }
                                        console.log(elP)
                                        toaster.pop('error', 'error data', elP);
    
    
                                    } else {
                                        toaster.pop('error', 'error data', g.err);
                                        return window.location=_API_URL+'purchasing?screen=prview';
    
    
                                    }
                                } else {
    
                                    toaster.pop('success', 'Success', 'Data disimpan');

                                    $scope.isEdit=true;
    
    
    
                                }
    
                            })
                        }
                        
                    } else {

                    }
                });

        };
        $scope.showItem = function (n) {
            console.log(n)
            $scope.getIndex = n;
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'itemList',
                params: {

                }

            });

            // modalForm.driverVendor();
        };
        $scope.showListVendor = function () {
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'vendorList',
                params: {

                }

            });
        }
        $scope.showListPRActive = function () {
		console.log('show ---pr');
                modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'pr-poList',
                params: {

                }
            });

        };
        $scope.getFromPr=function(No){
            console.log(No);
        };
        $scope.detailAdd = function () {
            $scope.detailJournal.push(angular.copy(defaultJournal));
        };
        $scope.detailRemove = function (x) {
            console.log(x);
            var index = $scope.detailJournal.indexOf(x);
            $scope.detailJournal.splice(index, 1);
            $scope.hitung();
        };
        $scope.addPO = function () {
            $scope.isEdit = false;
            $scope.data = {
                Tanggal: moment()._d,
                TanggalButuh: moment()._d,
                DiscPersen: 0,
                Disc:0,
                SubTotalDisc: 0,
                NilaiPPN: 0,
                PPnPersen: 0,
                Total: 0
            };
            $scope.detailJournal.push(angular.copy(defaultJournal));
        };
        $scope.editPO=function(){
        
            // purchase-requisition/:prno/masterdetail
            urlServices.ambilData('api/po/' + $scope.data.NoBukti+'/masterdetail', {}, function (e, s) {
                console.log(s);
                if (e) {

                    toaster.pop('error', 'Error', e.err);
                      $scope.goBack();
                } else {
                    $scope.isEdit = true;
                    var master = s.master[0];
                    if (master.is_approve_pr){
                        toaster.pop('error', 'Error', 'PR ini sudah di approve');
                        $scope.goBack();
                    }else{
                        $scope.data.NoBukti =master.NoBukti;
                        $scope.data.Tanggal=master.Tanggal;
                        $scope.data.TanggalButuh=master.TanggalButuh;
                        $scope.data.Keterangan=master.Keterangan;
                        $scope.data.PesanOleh=master.PesanOleh;
                        $scope.data.Vendor = master.Vendor;
                        $scope.data.Vendor_Name=master.Vendor_Name;
                        $scope.data.DiscPersen=master.DiscPersen;
                        $scope.data.PPnPersen=master.PPnPersen;
                        $scope.detailJournal=s.detail;
                        $scope.hitung();
                    }
                }
            });
        };
        $scope.addFromPr = function (dt) {
            console.log('add form pr');
            $scope.data.NOPO = dt.master.NoBukti;
            $scope.data.PesanOleh=dt.master.PesanOleh;
            $scope.data.Keterangan=dt.master.Keterangan;
            console.log($scope.detailJournal);
            if ($scope.detailJournal.length==1 && !$scope.detailJournal[0].KodeBarang){
                $scope.detailJournal=[];
            };
            angular.forEach(dt.detail, function (key, value) {
                console.log(key);
                $scope.detailJournal.push({
                    KodeBarang: key.KodeBarang,
                    NamaBarang: key.NamaBarang,
                    DescInt: key.DescInt,
                    DescExt: key.DescInt,
                    Satuan: key.Satuan,
                    Qty: key.Qty - key.Qty_PO,
                    HargaSystem:key.HargaSatuan,
                    HargaSatuan: key.HargaSatuan,
                    no_pr:dt.master.NoBukti,
                    SubTotal: (key.Qty - key.Qty_PO) * key.HargaSatuan
                })
            });
            $scope.hitung();

        };
        $scope.$on('pilih-vendor', function (event, args) {
            console.log('pilih -vendor')
            $scope.data.Vendor=args.vendor.KodeCustomer;
            $scope.data.Vendor_Name=args.vendor.NamaCustomer;
        })
        $scope.$on('pilih-item', function (event, args) {
            console.log($scope.getIndex);
            console.log($scope.detailJournal);
            console.log('update');
            $scope.detailJournal[$scope.getIndex].KodeBarang = args.item.KodeBarang;
            $scope.detailJournal[$scope.getIndex].NamaBarang = args.item.NamaBarang;
            $scope.detailJournal[$scope.getIndex].HargaSatuan = args.item.HargaBeli;
            $scope.detailJournal[$scope.getIndex].Satuan = args.item.Satuan;
            $scope.detailJournal[$scope.getIndex].Qty = $scope.detailJournal[$scope.getIndex].Qty ? $scope.detailJournal[$scope.getIndex].Qty : 1;
            $scope.priceQtyChange($scope.getIndex);
            $scope.getIndex = null;
            console.log($scope.detailJournal);
            console.log(args);
        });
        $scope.$on('pilih-driver-vendor', function (event, args) {
            console.log(args);
            $scope.data.contact_id = args.contact_id;
            $scope.data.contact_name = args.name;
        });
        $scope.$on('pilih-pr', function (event, args) {
            console.log(args);
            console.log('pilililili')
            $scope.pilihPrNo=args.pr.NoBukti;
           
            
                 urlServices.ambilData('api/po-pr/' + $scope.pilihPrNo, {}, function (e, s) {

                    console.log(s);
                    console.log(e);
                    if (e) {

                        toaster.pop('error', 'Error', e.data.err);
                        // $scope.addPO();
                    } else {
                        $scope.addFromPr(s);
                    }
                });
        })

        $scope.hitung = function () {
            var totalAmount = $filter('sumByKey')($scope.detailJournal, 'SubTotal');
            var taxAmount = 0;
            var discAmount=0;
            var totalDisc=0;
            var totalTax=0;
            $scope.data.SubTotal=totalAmount;
            if ($scope.data.DiscPersen) {
                discAmount = parseFloat($scope.data.DiscPersen || 0) / 100 * parseFloat(totalAmount);
                totalDisc=totalAmount - discAmount;
            } else {
                discAmount = 0;
                totalDisc=totalAmount;
            };
            if ($scope.data.PPnPersen) {
                taxAmount = parseFloat($scope.data.PPnPersen || 0) / 100 * parseFloat(totalDisc);
                totalTax=totalDisc + taxAmount;
            } else {
                taxAmount = 0;
                totalTax=totalDisc;
            };
            $scope.data.Disc = discAmount;
            $scope.data.SubTotalDisc=totalDisc;
            $scope.data.NilaiPPN=taxAmount;
            $scope.data.Total = totalTax;
        }

        $scope.priceQtyChange = function (x) {
            var dt = $scope.detailJournal[x];
            var price = dt.HargaSatuan || 0;
            var qt = dt.Qty || 0;
            $scope.detailJournal[x].SubTotal = parseFloat(price) * parseFloat(qt);
            $scope.hitung();
            return true;
        };
        if (urlServices.getUrlParameter('isedit') == 'true') {
            $scope.data.NoBukti= urlServices.getUrlParameter('pono');
            $scope.editPO();
             
          
        } else {
            $scope.addPO();
        };
    });
