app
    .controller('manifestBaggingDeliveryViewCtrl', function ($rootScope, $scope,_API_URL, $state, $window, shareData,modalFactory,modalService, $filter, urlServices, _VAR_GLOBAL,toaster,$location) {
        $scope.filter = '';
        $scope.dataList = {
            totalItems: 0,
            summary:{},
            data: []
        };
        $scope.paginationOptions = {
            pageNumber: 1,
            pageSize: _VAR_GLOBAL.limit,
            sort: null
        };
        $scope.perTglP = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment()
        };
        $scope.confirmFinish=function(dt){
            $scope.dataSelesai=dt;
            modalService.showModal({}, {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                headerText: 'Selesai ',
                bodyText: 'selesai : ' +  dt.NoBukti
            })
            .then(function (result) {
                
                if (result) {
                    urlServices.simpanData('api/baging-delivery/'+$scope.dataSelesai.NoBukti+'/finish', {}, function (g, s) {
                        if(g){
                            console.log('error');
                            toaster.pop('error','Error',g);
                        }else{
                            toaster.pop('success','sucess',s);
                        }
                    })
                }
            });
        };
        $scope.viewData = function (pg,qry) {
            console.log($scope.filter);
            $scope.paginationOptions.pageNumber = pg;
            $scope.getData($scope.paginationOptions.pageSize, $scope.paginationOptions.pageNumber, $scope.filter,qry);
        };
        $scope.detail=function(n){
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'poViewList',
                params: {
                    no:n.NoBukti
                }
            });
        };
       
        $scope.getData = function (pageSize, page, searchText, qry) {
            var qry = qry||{};
            
            setTimeout(function () {
                var data;
                if (searchText) {
                    var ft = searchText.toLowerCase();
                    data = 'term=' + ft + '&limit=' + pageSize + '&pg=' + page + '&start_date=' + $filter('date')($scope.perTglP.startDate._d, "yyyy-MM-dd") + '&end_date=' + $filter('date')($scope.perTglP.endDate._d, "yyyy-MM-dd");


                } else {
                    data = 'limit=' + pageSize + '&pg=' + page +   '&start_date=' + $filter('date')($scope.perTglP.startDate._d, "yyyy-MM-dd") + '&end_date=' + $filter('date')($scope.perTglP.endDate._d, "yyyy-MM-dd");
                }
                console.log('---->',data);
                urlServices.ambilData('api/baging-delivery?' + data, {}, function (err, sukses) {
                    console.log(sukses);
                    if (err) {
                        toaster.pop('error', 'Error', e);

                        $scope.dataList.totalItems = 0;
                        $scope.dataList.data = []
                    } else {
                        //console.log(sukses);
                        $scope.dataList.totalItems = sukses.records;
                        $scope.dataList.data = sukses.data;
                        console.log($scope.dataList);
                    }
                })

            }, 100);
        };

        $scope.viewData(1);
    })
    .controller('manifestBaggingDeliveryEntryCtrl',function ($rootScope, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        var defaultJournal = {
            Satuan: "PCS",
            HargaSatuan: 0,
            Qty:1,
            SubTotal: 0.00
        };
        $scope.deliveryData = [];
        $scope.data = {};
        $scope.isEdit = false;
        $scope.data = {};
        $scope.detailData = {};
        $scope.undelData=[];
        $scope.getIndex = null;
        $scope.showListVendor = function () {
            console.log('show')
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'vendorList',
                params: {

                }

            });
        };
       
        $scope.saveSJ=function(){
            modalService.showModal({}, {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                headerText: 'New',
                bodyText: 'Simpan Manifest ?'
            })
            .then(function (result) {
                if (result) {
                    console.log(new Date($scope.data.Tanggal));
                    if ($scope.data.Tanggal && $scope.data.Tanggal._d){
                        console.log('with d');
                        $scope.data.Tanggal=$scope.data.Tanggal._d;
                    };
                    if ($scope.data && !$scope.data.vendor){
                        $scope.data.vendor='-';
                        $scope.data.namaVendor='-';
                    };
                    if ($scope.data && !$scope.data.statusCode){
                        $scope.data.statusCode='-';
                        $scope.data.statusDesc='-';
                    };
                    if ($scope.data && !$scope.data.keterangan){
                        $scope.data.keterangan='-';
                        
                    };
                    if ($scope.data && !$scope.data.refNo){
                        $scope.data.refNo='-';
                        
                    };
                    
                    console.log($scope.data);
                    console.log($scope.deliveryData);
                    $scope.data.detail=$scope.deliveryData;
                    if ($scope.isEdit){
                       
                        urlServices.updateData('api/baging-delivery', $scope.data, function (g, s) {
                            console.log(s);
                            console.log(g);
                            if (g) {
                                if (g && g.err && g.err instanceof Array) {
                                    console.log('error');
                                    $scope.errorMessage = g.err;
                                    var elP = '';
                                    for (var key in $scope.errorMessage) {
                                        elP +=  $scope.errorMessage[key].msg;
                                    }
                                    console.log(elP)
                                    toaster.pop('error', 'error data', elP);
            
            
                                } else {
                                    toaster.pop('error', 'error data', g.err);
                                    window.location=_API_URL+'manifest-baging-delivery-view';
            
                                }
                            } else {
                                $scope.addSj();
                                toaster.pop('success', 'Success', 'Data disimpan');
                                
                                
            
            
                            }
            
                        })
                    }else{
                        urlServices.simpanData('api/baging-delivery', $scope.data, function (g, s) {
                            console.log(s);
                            console.log(g);
                            if (g) {
                                if (g && g.err && g.err instanceof Array) {
                                    console.log('error');
                                    $scope.errorMessage = g.err;
                                    var elP = '';
                                    for (var key in $scope.errorMessage) {
                                        elP +=  $scope.errorMessage[key].msg;
                                    }
                                    console.log(elP)
                                    toaster.pop('error', 'error data', elP);
            
            
                                } else {
                                    toaster.pop('error', 'error data', g.err);
            
            
                                }
                            } else {
                                $scope.addSj();
                                toaster.pop('success', 'Success', 'Data disimpan');
                                
                                
            
            
                            }
            
                        })
                        
                    }
                
                }else{

                }
            });
           
        };
        $scope.addSj=function(){
            $scope.isEdit = false;
            $scope.data = {
                Tanggal: moment()._d
            };
            $scope.deliveryData = [];
            $scope.getBagingUndel();
        };
        $scope.removeUndel=function(dt){
            console.log('undel',dt);
            var index = $scope.undelData.indexOf(dt);
            $scope.undelData.splice(index, 1);
        };
        $scope.removeDel=function(dt){
            var index = $scope.deliveryData.indexOf(dt);
            dt.IsSuratJalan=false;
            $scope.deliveryData.splice(index, 1);
            $scope.undelData.push(angular.copy(dt));
        };
        $scope.confirmManifest=function(dt){
            console.log(dt)
            if(urlServices.cariArrayData(dt.ManifestID,$scope.deliveryData,'ManifestID')){
                toaster.pop('error','Data sudah ada','Sudah di masukan')
            }else{
                
                $scope.deliveryData.push(angular.copy(dt));
                $scope.removeUndel(dt);
            }
        };
        $scope.unConfirmManifest=function(dt){

        };
        $scope.showBagingDetail=function(bagid){
            console.log(bagid);
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'baggingDetailList',
                params: {
                    no:bagid
                }
            });
        };
        $scope.pilihSatu = function (id) {
            console.log(urlServices.cariArrayData(id.rec_id,$scope.undelData,'rec_id'));
            console.log(id);
            var isManifest = id.IsSuratJalan;
            console.log(isManifest);
                $scope.confirmManifest(id)
            
        };
        $scope.editSj=function(){
            $scope.getBagingUndel();
        };
        $scope.getBagingUndel=function(){
            urlServices.ambilData('api/baging-undelivery', {}, function (e, s) {
                console.log('dashboard');
                console.log(s);
                console.log(e);
                if (e) {
                    $scope.undelData=[];
                } else {
                    $scope.undelData=s;
                }
            });
        };
        $scope.$on('pilih-vendor', function (event, args) {
            console.log('pilih -vendor')
            $scope.data.vendor=args.vendor.KodeCustomer;
            $scope.data.namaVendor=args.vendor.NamaCustomer;
        })
        if (urlServices.getUrlParameter('isedit')=='false'){
            $scope.addSj();
        }else{
            if (urlServices.getUrlParameter('sj')){
              
                $scope.editSj(urlServices.getUrlParameter('prno'));
            }else{
                toaster.pop('error','error','Tidak ada no pr yang diedit');
                $scope.goBack();
            }
        }
    })
    .controller('manifestBaggingDeliveryPrintCtrl', function ($rootScope,_API_URL, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        var NoBukti = urlServices.getUrlParameter('no');
       
        
      
        $scope.options = {
            width: 1,
            height: 50,
            quite: 10,
            displayValue: false,
            font: "monospace",
            textAlign: "center",
            fontSize: 12,
            backgroundColor: "",
            lineColor: "#000"
        };
        

        urlServices.ambilData('api/baging-delivery/' + NoBukti + '/detail' , {}, function (e, d) {
            console.log(e)
            if (d) {
                $scope.dataGroup =d;
            } else {
                toaster.pop('error', 'error data', 'Data tidak bisa di proses' + e.err);

            }
            console.log($scope.dataGroup);
            // $scope.dataCustomer=args.dt;
        })
 
 
    });
