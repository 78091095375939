app.controller('userTrxCtrl', function ($rootScope, $scope, $state, $stateParams, urlServices, $filter, toaster, _VAR_GLOBAL, modalFactory,$timeout) {
    $scope.isReadOnly = false;
    $scope.isEdit = false;
    $scope.addContact = function () {
        $scope.isReadOnly = false;
        $scope.isEdit = false;
        $scope.dataCustomer = {
        };
    };
    $scope.listMenu=[];
    $scope.listAddMenu =[];
    $scope.showListContact = function (isFrom) {
        modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
            functionName: 'contactList',
        });
        // modalForm.cityList(isFrom);
    };
    $scope.addContact=function(){
        $scope.dataUser={};
        $scope.isEdit=false;
        $scope.listAddMenu=[];
        $scope.listGroupMenu = [];
    };
    $scope.saveContact = function () {
        $scope.dataUser.listMenu = JSON.stringify($scope.listAddMenu);
        if ($scope.isEdit) {
            urlServices.updateData('api/user/'+$scope.dataUser.NamaUser, $scope.dataUser, function (g, s) {
                if (g) {
                    if (g && g.err && g.err instanceof Array) {
                        $scope.errorMessage = g.err;
                        var elP = '';
                        for (var key in $scope.errorMessage) {
                            elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                        }
                        toaster.pop('error', 'Error', elP);
                    } else {
                        toaster.pop('error', 'Error', g.err);
                    }
                } else if (s) {
                    $scope.addContact();
                    toaster.pop('success', 'User', 'User updated');
                }
            })
        } else {
            urlServices.simpanData('api/user', $scope.dataUser, function (g, s) {
                if (g) {
                    if (g && g.err && g.err instanceof Array) {
                        $scope.errorMessage = g.err;
                        var elP = '';
                        for (var key in $scope.errorMessage) {
                            elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                        }
                        toaster.pop('error', 'Error', elP);
                    } else {
                        toaster.pop('error', 'Error', g.err);
                    }
                } else if (s) {
                    $scope.addContact();
                    toaster.pop('success', 'Contact', 'Contact updated');
                }
            })
        }
    }
    $scope.cariDataContact = function (id) {
        urlServices.ambilData('api/user/'+id, {}, function (e, d) {
            if (d) {
                $scope.isReadOnly = true;
                $scope.isEdit = true;
                $scope.dataUser = d;
                $scope.listAddMenu=d.tb_menus;
                delete $scope.dataUser['tb_user_menus'];
                for (var i = 0; i < $scope.listAddMenu.length; i++) {
                    for (var j = 0; j < $scope.listMenu.length; j++) {
                        if ($scope.listMenu[j]["id_module"] ===  $scope.listAddMenu[i]["id_module"]) {
                            $scope.listMenu.splice(j,1);
                        }
                    }
                }
                $scope.compareMenu();
            } else {
                toaster.pop('error', 'Error', 'Error mengambil datas');
            }
            // $scope.dataCustomer=args.dt;
        })
    };
    $scope.getMenu=function(){
        urlServices.ambilData('api/usermenu', {
        }, function (e, d) {
            if (d) {
                $scope.listMenu = d;
                $scope.compareMenu();
            } else {
                toaster.pop('error', 'Error', 'Error mengambil datas');
            }
            // $scope.dataCustomer=args.dt;
        })
    };
    $scope.removeMenu=function(dt){
        for (var i = 0; i < $scope.listAddMenu.length; i++) {
            if ($scope.listAddMenu[i]["id_module"] === dt.id_module) {
                $scope.listMenu.push(urlServices.cariArrayData(dt.module_name, $scope.listAddMenu, 'module_name'));
                $scope.listAddMenu.splice(i,1);
            }
        }
        return $scope.compareMenu();
    };
    $scope.addMenu=function(dt){
        if (urlServices.cariArrayData(dt.module_name, $scope.listAddMenu, 'module_name')){
            toaster.pop('error', 'Error Data', 'Menu sudah ada');
        }else{
            for (var i = 0; i < $scope.listMenu.length; i++) {
                if ($scope.listMenu[i]["id_module"] === dt.id_module) {
                    $scope.listAddMenu.push(urlServices.cariArrayData(dt.module_name, $scope.listMenu, 'module_name')); 
                    $scope.listMenu.splice(i,1);
                }
            }
            //
            return $scope.compareMenu();
        };
    }
    $scope.compareMenu=function(){
        $timeout(function(){
            $scope.groupMenu =  $scope.createTree($scope.listMenu,'module_group');
            $scope.listGroupMenu =  $scope.createTree( $scope.listAddMenu,'module_group');
        },100)
    }
    $scope.createTree=function(data,group){
        var grouped =_.mapValues(_.groupBy(data, group), function (clist) {
          return clist.map(function (data) {
            return _.omit(data, group);
          });
        });
        return grouped;
    };
    $scope.$on('pilih-contact', function (event, args) {
        $scope.dataUser.contact_id=args.contact_id;
        $scope.dataUser.name=args.name;
        return true;
        // do what you want to do
    });
    $scope.getMenu();
    var isEdit = urlServices.getUrlParameter('isedit')||false;
    if (isEdit){
        $scope.edit=true;
        var id =urlServices.getUrlParameter('id')||null;
        $scope.cariDataContact(id);
    }else{
        $scope.edit=false;
        $scope.addContact();
    }
})
