app
    .controller('prTrxCtrl', function ($rootScope, $scope, $state, $location, $filter, urlServices,modalService, modalFactory,toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        console.log(urlServices.getUrlParameter('isedit'))
        var defaultJournal = {
            Satuan: "PCS",
            HargaSatuan: 0,
            Qty:1,
            SubTotal: 0.00
        };
        $scope.detailJournal = [];
        $scope.data = {};
        $scope.isEdit = false;
        $scope.data = {};
        $scope.detailData = {};
        $scope.getIndex = null;
        $scope.savePR=function(){
            modalService.showModal({}, {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                headerText: 'New',
                bodyText: 'Simpan Purchase Request?'
            })
            .then(function (result) {
                if (result) {
                    console.log(new Date($scope.data.Tanggal));
                    if ($scope.data.Tanggal && $scope.data.Tanggal._d){
                        console.log('with d');
                        $scope.data.Tanggal=$scope.data.Tanggal._d;
                    };
                    if ($scope.data.TanggalButuh && $scope.data.TanggalButuh._d){
                        console.log('with d');
                        $scope.data.TanggalButuh=$scope.data.TanggalButuh._d;
                    };
                    
                    console.log($scope.data);
                    console.log($scope.detailJournal);
                    $scope.data.SubTotal=$filter('sumByKey')($scope.detailJournal, 'SubTotal');
                    $scope.data.TotalQty=$filter('sumByKey')($scope.detailJournal, 'Qty')
                    $scope.data.detail=$scope.detailJournal;
                    if ($scope.isEdit){
                       
                        urlServices.updateData('api/purchase-requisition', $scope.data, function (g, s) {
                            console.log(s);
                            console.log(g);
                            if (g) {
                                if (g && g.err && g.err instanceof Array) {
                                    console.log('error');
                                    $scope.errorMessage = g.err;
                                    var elP = '';
                                    for (var key in $scope.errorMessage) {
                                        elP +=  $scope.errorMessage[key].msg;
                                    }
                                    console.log(elP)
                                    toaster.pop('error', 'error data', elP);
            
            
                                } else {
                                    toaster.pop('error', 'error data', g.err);
                                    window.location=_API_URL+'purchasing?screen=prview';
            
                                }
                            } else {
                                
                                toaster.pop('success', 'Success', 'Data disimpan');
                                window.location=_API_URL+'purchasing?screen=prview';
                                
            
            
                            }
            
                        })
                    }else{
                        urlServices.simpanData('api/purchase-requisition', $scope.data, function (g, s) {
                            console.log(s);
                            console.log(g);
                            if (g) {
                                if (g && g.err && g.err instanceof Array) {
                                    console.log('error');
                                    $scope.errorMessage = g.err;
                                    var elP = '';
                                    for (var key in $scope.errorMessage) {
                                        elP +=  $scope.errorMessage[key].msg;
                                    }
                                    console.log(elP)
                                    toaster.pop('error', 'error data', elP);
            
            
                                } else {
                                    toaster.pop('error', 'error data', g.err);
            
            
                                }
                            } else {
                                
                                toaster.pop('success', 'Success', 'Data disimpan');
                                return window.location=_API_URL+'purchasing?screen=prview';
                                
            
            
                            }
            
                        })
                        
                    }
                
                }else{

                }
            });
           
        };
        $scope.showItem = function (n) {
            console.log(n)
            $scope.getIndex = n;
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'itemList',
                params: {

                }

            });

            // modalForm.driverVendor();
        };
        $scope.detailAdd = function () {
            $scope.detailJournal.push(angular.copy(defaultJournal));
        };
        $scope.detailRemove = function (x) {
            console.log(x);
            var index = $scope.detailJournal.indexOf(x);
            $scope.detailJournal.splice(index, 1);
        };
        $scope.cariExpense = function (id) {

        };
        $scope.goBack=function(){
             window.location=_API_URL+'purchasing?screen=prview';
        }
        $scope.editPR=function(dt){
            $scope.isEdit = true;
            // purchase-requisition/:prno/masterdetail
            urlServices.ambilData('api/purchase-requisition/' + dt+'/masterdetail', {}, function (e, s) {
                console.log(s);
                if (e) {

                    toaster.pop('error', 'Error', e.err);
                      $scope.goBack();
                } else {
                    var master = s.master[0];
                    if (master.is_approve_pr){
                        toaster.pop('error', 'Error', 'PR ini sudah di approve');
                        $scope.goBack();
                    }else{
                        $scope.data.NoBukti =master.NoBukti;
                        $scope.data.Tanggal=master.Tanggal;
                        $scope.data.TanggalButuh=master.TanggalButuh;
                        $scope.data.Keterangan=master.Keterangan;
                        $scope.data.PesanOleh=master.PesanOleh;
                        $scope.detailJournal=s.detail;
                    }
                }
            });
        };
        $scope.addPR = function () {
            $scope.isEdit = false;
            $scope.data = {
                Tanggal: moment()._d,
                TanggalButuh:moment()._d
            };
            $scope.detailJournal.push(angular.copy(defaultJournal));
        }
        $scope.$on('pilih-item', function (event, args) {
            console.log($scope.detailJournal);
            if(urlServices.cariArrayData(args.item.KodeBarang,$scope.detailJournal,'KodeBarang')){
                toaster.pop('error','Data sudah ada','Sudah di masukan')
            } else {
                $scope.detailJournal[$scope.getIndex].KodeBarang = args.item.KodeBarang;
            $scope.detailJournal[$scope.getIndex].NamaBarang = args.item.NamaBarang;
            $scope.detailJournal[$scope.getIndex].HargaSatuan = args.item.HargaBeli;
            $scope.detailJournal[$scope.getIndex].Satuan = args.item.Satuan;
            $scope.detailJournal[$scope.getIndex].Qty = $scope.detailJournal[$scope.getIndex].Qty?$scope.detailJournal[$scope.getIndex].Qty : 1;
            $scope.priceQtyChange($scope.getIndex);
            $scope.getIndex = null;
            console.log($scope.detailJournal);

            }
        
        });
        $scope.$on('pilih-driver-vendor', function (event, args) {
            console.log(args);
            $scope.data.contact_id = args.contact_id;
            $scope.data.contact_name = args.name;
        });

      

        $scope.priceQtyChange = function (x) {

            var dt = $scope.detailJournal[x];
            var price = dt.HargaSatuan || 0;
            var qt = dt.Qty || 0;
            $scope.detailJournal[x].SubTotal = parseFloat(price) * parseFloat(qt);
            $scope.taxChange();
            return true;
        };
        $scope.taxChange = function () {
            // console.log($filter('sumByKey')($scope.detailJournal, 'SubTotal'))
            var totalAmount = $filter('sumByKey')($scope.detailJournal, 'SubTotal');
            var taxAmount = 0;
            if ($scope.data.tax_value) {
                taxAmount = parseFloat($scope.data.tax_value || 0) / 100 * parseFloat(SubTotal);
            } else {
                taxAmount = 0;
            };
            $scope.data.tax_amount = taxAmount;
            $scope.data.total = totalAmount + taxAmount;

        };

    
        if (urlServices.getUrlParameter('isedit')=='true'){
           
            if (urlServices.getUrlParameter('prno')){
                $scope.addPR();
                $scope.editPR(urlServices.getUrlParameter('prno'));
            }else{
                toaster.pop('error','error','Tidak ada no pr yang diedit');
                $scope.goBack();
            }
        }else{
            $scope.addPR();
            
        }
        
    });