app
    .controller('manifestBaggingHostPrintCtrl', function ($rootScope,_API_URL, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        var host = urlServices.getUrlParameter('host');
        var startDate = urlServices.getUrlParameter('start_date');
        var moda = urlServices.getUrlParameter('moda');
        
        $scope.master={};
        $scope.detail=[];
        $scope.vendor=[];
        $scope.options = {
            width: 1,
            height: 50,
            quite: 10,
            displayValue: false,
            font: "monospace",
            textAlign: "center",
            fontSize: 12,
            backgroundColor: "",
            lineColor: "#000"
        };
        $scope.master={
            host:host,
            date:new Date(startDate),
            moda:moda
        };

        urlServices.ambilData('api/bagging/' + host + '/' +moda +'/detailshipment?start_date=' + startDate , {}, function (e, d) {
            console.log(d)
            if (d) {
                $scope.dataGroup = urlServices.createTree(d,'AWB');
            } else {
                toaster.pop('error', 'error data', 'Data tidak bisa di proses' + e.err);

            }
            console.log($scope.dataGroup);
            // $scope.dataCustomer=args.dt;
        })
 
 
    });