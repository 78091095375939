app.controller('arViewCtrl', function ($rootScope, $scope, urlServices, $filter, toaster, _VAR_GLOBAL, modalFactory, modalService, $window) {
    var paginationOptions = {
        pageNumber: 1,
        pageSize: _VAR_GLOBAL.limit,
        sort: null
    };
    $scope.perTglP =  moment()._d;
    $scope.params={};
    $scope.dataList=[];
    $scope.filterOptions = {
        filterText: "",
        useExternalFilter: true
    };
    $scope.filterSearch = function () {
        $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');
    };

    $scope.getPagedDataAsync = function (pageSize, page, searchText) {
        console.log('page data asynk')
        setTimeout(function () {
            var data;
            console.log('Search text', searchText);
            if (searchText) {
                var ft = searchText.toLowerCase();
                var qry = 'qry=' + JSON.stringify($scope.optionsParams.qry) + '&pg=' + page + '&limit=' + pageSize + '&term=' + ft;
            } else {
                var qry = 'qry=' + JSON.stringify($scope.optionsParams.qry) + '&pg=' + page + '&limit=' + pageSize;
            }
            // console.log($scope.optionsParams.url + qry);
            urlServices.ambilData('api/ar/view?as_of=' + $filter('date')($scope.params.as_of, "yyyy-MM-dd")+ '&'+qry, {}, function (e, d) {
                console.log('-------', d);
                if (e) {
                    if (e && e.err && e.err instanceof Array) {
                        $scope.errorMessage = e.err;
                        var elP = '';
                        for (var key in $scope.errorMessage) {
                            elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                        }

                        toaster.pop('error', 'Error Data', elP);

                    } else {

                        toaster.pop('error', 'Error Data', e.err);

                    }
                    $scope.gridOptions.totalItems = 0;
                    $scope.gridOptions.data = []

                } else {
                    //console.log(d);
                    if (d && d.count) {

                        $scope.gridOptions.totalItems = d.count;
                        $scope.gridOptions.data = d.rows;

                    } else {
                        console.log('herere',d);
                        if (d && !d.total) {
                            $scope.gridOptions.totalItems = d.length;
                            $scope.gridOptions.data = d;
                        } else
                         {
                            $scope.gridOptions.totalItems = d.total;
                            $scope.gridOptions.data = d.dt;
                        }
                    }

                }
            })
        }, 100);
    };

    $scope.gridOptions = {
        paginationPageSizes: [10, 20, 50, 100],
        paginationPageSize: _VAR_GLOBAL.limit,
        enableColumnResizing: true,
        useExternalPagination: true,
        useExternalSorting: true,
        columnDefs: [],
        onRegisterApi: function (gridApi) {

            $scope.gridApi = gridApi;
            $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                if (sortColumns.length == 0) {
                    paginationOptions.sort = null;
                } else {
                    paginationOptions.sort = sortColumns[0].sort.direction;
                }
                $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');
            });
            gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                paginationOptions.pageNumber = newPage;
                paginationOptions.pageSize = pageSize;
                $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');
            });
        }
    };
    $scope.viewData=function(){


        console.log($scope.perTglP);
        if ($scope.perTglP && $scope.perTglP._d){
            $scope.params.as_of=$scope.perTglP._d;
        }else{
            $scope.params.as_of=$scope.perTglP;
        }

        // $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');

        $window.location.href='/report-ar?isexcel=true&as_of=' + $filter('date')($scope.params.as_of, "yyyy-MM-dd");

        // urlServices.ambilData('api/ar/view?isexcel=true&as_of=' + $filter('date')($scope.params.as_of, "yyyy-MM-dd"), {}, function (e, s) {
        //     console.log('dashboard');
        //     console.log(s);
        //     console.log(e);
        //     if (e) {
        //         $scope.dataList=[];
        //     } else {
        //         $scope.dataList=s;
        //     }
        // });
    };
    // $scope.viewData();
}).controller('apViewCtrl', function ($rootScope, $scope, urlServices, $filter, toaster, _VAR_GLOBAL, modalFactory, modalService, $window) {
    $scope.perTglP =  moment()._d;
    $scope.params={};
    $scope.dataList=[];
     
    $scope.viewData=function(){
        console.log($scope.perTglP);
        if ($scope.perTglP && $scope.perTglP._d){
            $scope.params.as_of=$scope.perTglP._d;
        }else{
            $scope.params.as_of=$scope.perTglP;
        };
        $window.location.href='/report-ap?isexcel=true&as_of=' + $filter('date')($scope.params.as_of, "yyyy-MM-dd");


        // urlServices.ambilData('api/ap/view?isexcel=true&as_of=' + $filter('date')($scope.params.as_of, "yyyy-MM-dd"), {}, function (e, s) {
        //     console.log('dashboard');
        //     console.log(s);
        //     console.log(e);
        //     if (e) {
        //         $scope.dataList=[];
        //     } else {
        //         $scope.dataList=s;
        //     }
        // });
    };
    // $scope.viewData();
})