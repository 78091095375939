app
    .controller('poCetakCtrl', function ($rootScope, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        $scope.master={};
        $scope.detail=[];
        $scope.vendor=[];
         
 
            var noPO = urlServices.getUrlParameter('pono');
            if (noPO) {
                urlServices.ambilData('api/po/' + noPO+'/masterdetail', {}, function (e, s) {
                    console.log(s);
                    if (e) {

                        toaster.pop('error', 'Error', e.err);
                        
                    } else {
                        $scope.master=s.master[0];
                        $scope.detail=s.detail;
                        $scope.vendor=s.vendor[0];
                        console.log($scope.detail)
                        
                    }
                });
            } else{
                return window.location=_API_URL+'poview';
            }
             
        
    });