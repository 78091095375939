app
    .controller('preViewCtrl', function ($rootScope, $scope,_API_URL, $state, $window, shareData,modalFactory,modalService, $filter, urlServices, _VAR_GLOBAL,toaster,$location) {
        $scope.filter = '';
        $scope.dataList = {
            totalItems: 0,
            summary:{},
            data: []
        };
        $scope.paginationOptions = {
            pageNumber: 1,
            pageSize: _VAR_GLOBAL.limit,
            sort: null
        };
        $scope.perTglP = {
            startDate: moment().subtract(30, 'days'),
            endDate: moment()
        };
        $scope.edit=function(dt){
            console.log(dt);
            return window.location=_API_URL+'purchasing?screen=pr&isedit=true&prno='+ dt.NoBukti;
            
        };
        $scope.refreshData=function(){
            return $scope.viewData(1,{is_approve:0});
        };
        $scope.hapus=function(dt){
            console.log(dt);
            $scope.NoBukti=dt.NoBukti;
            modalService.showModal({}, {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                headerText: 'Hapus PR',
                bodyText: 'Hapus Purchase Request?'
            })
            .then(function (result) {
                if (result) {
                    urlServices.hapusData('api/purchase-requisition?prno='+ $scope.NoBukti,{}, function (g, s) {
                        if (g) {
                            if (g && g.err && g.err instanceof Array) {
                                console.log('error');
                                $scope.errorMessage = g.err;
                                var elP = '';
                                for (var key in $scope.errorMessage) {
                                    elP +=  $scope.errorMessage[key].msg;
                                }
                                console.log(elP)
                                toaster.pop('error', 'error data', elP);
        
        
                            } else {
                                toaster.pop('error', 'error data', g.err);
                            
        
                            }
                        } else {
                            
                            toaster.pop('success', 'Success', 'Data dihapus');
                            $scope.refreshData();
                            
        
        
                        }
                    })

                }
            })
            
        };

         

        $scope.viewData = function (pg,qry) {
            console.log($scope.filter);
            $scope.paginationOptions.pageNumber = pg;
            $scope.getData($scope.paginationOptions.pageSize, $scope.paginationOptions.pageNumber, $scope.filter,qry);
        };
        $scope.detail=function(n){
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'preViewList',
                params: {
                    no:n.NoBukti
                }
            });
        };
		$scope.viewDashboard = function () {
            urlServices.ambilData('api/preceive/summary?start_date=' + $filter('date')($scope.perTglP.startDate._d, "yyyy-MM-dd") + '&end_date=' + $filter('date')($scope.perTglP.endDate._d, "yyyy-MM-dd"), {}, function (e, s) {
                console.log('dashboard');
                console.log(s);
                console.log(e);
                if (e) {
                    $scope.dataList.summary={};
                } else {
                    $scope.dataList.summary=s[0];
                }
            });
        };
        $scope.getData = function (pageSize, page, searchText, qry) {
            var qry = qry||null;
			$scope.viewDashboard();
            setTimeout(function () {
                var data;
                if (searchText) {
                    var ft = searchText.toLowerCase();
                    data = 'term=' + ft + '&limit=' + pageSize + '&pg=' + page + '&start_date=' + $filter('date')($scope.perTglP.startDate._d, "yyyy-MM-dd") + '&end_date=' + $filter('date')($scope.perTglP.endDate._d, "yyyy-MM-dd");
					if (qry){
                        data +='&status='+qry;
                    }

                } else {
                    data = 'limit=' + pageSize + '&pg=' + page +   '&start_date=' + $filter('date')($scope.perTglP.startDate._d, "yyyy-MM-dd") + '&end_date=' + $filter('date')($scope.perTglP.endDate._d, "yyyy-MM-dd");
					if (qry){
                        data +='&status='+qry;
                    };
                }
				console.log(qry);
                console.log('---->',data);
                urlServices.ambilData('api/preceive/filter?' + data, {}, function (err, sukses) {
                    console.log(sukses);
                    if (err) {
                        toaster.pop('error', 'Error', e);

                        $scope.dataList.totalItems = 0;
                        $scope.dataList.data = []
                    } else {
                        //console.log(sukses);
                        $scope.dataList.totalItems = sukses.records;
                        $scope.dataList.data = sukses.data;
                        console.log($scope.dataList);
                    }
                })

            }, 100);
        };
        $scope.$watch('perTglP', function (newDate) {
            //console.log('New date set: ', newDate);
            $scope.viewData(1);
       
        }, false);
        $scope.viewData(1);
    })

