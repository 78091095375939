app
    .controller('preceiveTrxCtrl', function ($rootScope, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        console.log(urlServices.getUrlParameter('pr'))
        var defaultJournal = {
            Satuan: "PCS",
            HargaSatuan: 0,
            QtyTerima: 1,
            SubTotal: 0.00
        };
        $scope.detailJournal = [];
        $scope.data = {
            Tanggal: moment()._d,
            TanggalButuh: moment()._d,
            DiscPersen: 0,
            Disc: 0,
            SubTotalDisc: 0,
            NilaiPPN: 0,
            PPnPersen: 0,
            Total: 0
        };
        $scope.isEdit = false;
        $scope.detailData = {};
        $scope.getIndex = null;
        $scope.savePReceive = function () {
            modalService.showModal({}, {
                    closeButtonText: 'No',
                    actionButtonText: 'Yes',
                    headerText: 'New',
                    bodyText: 'Simpan Purchase Receive dari PO?'
                })
                .then(function (result) {
                    if (result) {
                        console.log(new Date($scope.data.Tanggal));
                        if ($scope.data.Tanggal && $scope.data.Tanggal._d) {
                            console.log('with d');
                            $scope.data.Tanggal = $scope.data.Tanggal._d;
                        };
                        if ($scope.data.TanggalButuh && $scope.data.TanggalButuh._d) {
                            console.log('with d');
                            $scope.data.TanggalButuh = $scope.data.TanggalButuh._d;
                        };

                        console.log($scope.data);
                        console.log($scope.detailJournal);
                        $scope.hitung();
                        $scope.data.TotalQty=$filter('sumByKey')($scope.detailJournal, 'QtyTerima')
                        $scope.data.detail = $scope.detailJournal;
                        urlServices.simpanData('api/preceive', $scope.data, function (g, s) {
                            console.log(s);
                            console.log(g);
                            if (g) {
                                if (g && g.err && g.err instanceof Array) {
                                    console.log('error');
                                    $scope.errorMessage = g.err;
                                    var elP = '';
                                    for (var key in $scope.errorMessage) {
                                        elP += $scope.errorMessage[key].msg;
                                    }
                                    console.log(elP)
                                    toaster.pop('error', 'error data', elP);


                                } else {
                                    toaster.pop('error', 'error data', g.err);
                                    return window.location=_API_URL+'preceiveview';


                                }
                            } else {

                                toaster.pop('success', 'Success', 'Data disimpan');




                            }

                        })
                    } else {

                    }
                });

        };
        $scope.showItem = function (n) {
            $scope.getIndex = n;
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'itemListPO',
                params: {
                    noPo:$scope.data.NOPO
                }

            });

            // modalForm.driverVendor();
        };
        $scope.showListPOReceive = function () {
            modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                functionName: 'poReceiveList',
                params: {

                }

            });
        };
        $scope.detailAdd = function () {
            $scope.detailJournal.push(angular.copy(defaultJournal));
        };
        $scope.detailRemove = function (x) {
            console.log(x);
            var index = $scope.detailJournal.indexOf(x);
            $scope.detailJournal.splice(index, 1);
            $scope.hitung();
        };
        $scope.addPReceive = function () {
            $scope.isEdit = false;
            $scope.data = {
                Tanggal: moment()._d,
                TanggalButuh: moment()._d,
                DiscPersen: 0,
                Disc:0,
                SubTotalDisc: 0,
                NilaiPPN: 0,
                PPnPersen: 0,
                Total: 0
            };
            
            $scope.detailJournal.push(angular.copy(defaultJournal));
        };
        $scope.addFromPr = function (dt) {
            console.log(dt);
            $scope.data.NOPO = dt.master.NoBukti;
            $scope.data.PesanOleh=dt.master.PesanOleh;
            $scope.data.Keterangan=dt.master.Keterangan;
            angular.forEach(dt.detail, function (key, value) {
                console.log(key);
                $scope.detailJournal.push({
                    KodeBarang: key.KodeBarang,
                    NamaBarang: key.NamaBarang,
                    DescInt: key.DescInt,
                    DescExt: key.DescInt,
                    Satuan: key.Satuan,
                    Qty: key.Qty - key.Qty_PO,
                    HargaSatuan: key.HargaSatuan,
                    SubTotal: (key.Qty - key.Qty_PO) * key.HargaSatuan
                })
            });
            $scope.hitung();

        };
        $scope.$on('pilih-po', function (event, args) {

            $scope.data.NOPO=args.po.NoBukti;
        })
        $scope.$on('pilih-item-po', function (event, args) {
            console.log($scope.getIndex);
            console.log($scope.detailJournal);
            console.log('update');
            if(urlServices.cariArrayData(args.item.KodeBarang,$scope.detailJournal,'KodeBarang')){
                toaster.pop('error','Data sudah ada','Sudah di masukan')
            }else{
                $scope.detailJournal[$scope.getIndex].KodeBarang = args.item.KodeBarang;
            $scope.detailJournal[$scope.getIndex].NamaBarang = args.item.NamaBarang;
            $scope.detailJournal[$scope.getIndex].HargaSatuan = args.item.HargaSatuan;
            $scope.detailJournal[$scope.getIndex].Satuan = args.item.Satuan;
            $scope.detailJournal[$scope.getIndex].QtyTerima =   args.item.Qty - args.item.QtyTerima;
            $scope.detailJournal[$scope.getIndex].QtyMax =   args.item.Qty - args.item.QtyTerima;
            $scope.priceQtyChange($scope.getIndex);
            $scope.getIndex = null;

            }
            
            console.log($scope.detailJournal);
            console.log(args);
        });
        $scope.hitung = function () {
            var totalAmount = $filter('sumByKey')($scope.detailJournal, 'SubTotal');
            var taxAmount = 0;
            var discAmount=0;
            var totalDisc=0;
            var totalTax=0;
            $scope.data.SubTotal=totalAmount;
            if ($scope.data.DiscPersen) {
                discAmount = parseFloat($scope.data.DiscPersen || 0) / 100 * parseFloat(totalAmount);
                totalDisc=totalAmount - discAmount;
            } else {
                discAmount = 0;
                totalDisc=totalAmount;
            };
            if ($scope.data.PPnPersen) {
                taxAmount = parseFloat($scope.data.PPnPersen || 0) / 100 * parseFloat(totalDisc);
                totalTax=totalDisc + taxAmount;
            } else {
                taxAmount = 0;
                totalTax=totalDisc;
            };

            
            $scope.data.Disc = discAmount;
            $scope.data.SubTotalDisc=totalDisc;
            $scope.data.NilaiPPN=taxAmount;
            
            $scope.data.Total = totalTax;
        }

        $scope.priceQtyChange = function (x) {

            var dt = $scope.detailJournal[x];
            var price = dt.HargaSatuan || 0;
            var qt = dt.QtyTerima || 0;
            $scope.detailJournal[x].SubTotal = parseFloat(price) * parseFloat(qt);
            $scope.hitung();
            return true;
        };
        
        if (urlServices.getUrlParameter('po') == 'true') {
            var noPR = urlServices.getUrlParameter('pono');
            if (noPR) {
                urlServices.ambilData('api/po-preceive/' + noPR, {}, function (e, s) {

                    console.log(s);
                    console.log(e);
                    if (e) {

                        toaster.pop('error', 'Error', e.err);
                        $scope.addPReceive();
                    } else {
                        $scope.addFromPr(s);
                    }
                });
            } else {
                $scope.addPReceive();
            }
            console.log('pr true');
        } else {
            $scope.addPReceive();
        }
    });