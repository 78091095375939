'use strict';
app.factory('urlServices', function (_API_URL, $window, $cookieStore, $http) {
    var x = $cookieStore.get('currentUsers') || {};
    var t = null;
    var service = {
        ambilToken: function () {
            if ($cookieStore.get('currentUsers') && $cookieStore.get('currentUsers').token) {
                return $cookieStore.get('currentUsers').token;
            } else {
                return null;
            }
        },
        baseUrl: _API_URL,
        buildUrl: function (subUrl) {
            return  subUrl;
        },
        serialize: function (obj) {
            var str = [];
            for (var p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
            return str.join("&");
        },
        getUrlParameter: function (param, dummyPath) {
            var sPageURL = dummyPath || window.location.search.substring(1),
                sURLVariables = sPageURL.split(/[&||?]/),
                res;
            for (var i = 0; i < sURLVariables.length; i += 1) {
                var paramName = sURLVariables[i],
                    sParameterName = (paramName || '').split('=');
                if (sParameterName[0] === param) {
                    res = sParameterName[1];
                }
            }
            return res;
        },
        hitungVol: function (param) {
            var p = param.p || 0,
                l = param.l || 0,
                t = param.t || 0
            var moda = param.moda || 'A';
            var vol;
            var weight;
            if (moda == 'A') {
                vol = p * l * t / 6000;
            };
            if (moda == 'L') {
                vol = p * l * t / 6000;
            };
            if (moda == 'S') {
                vol = p * l * t / 1000000;
            };
            return vol || 0;
        },
        ambilData: function (urlReq, params, hasil) {
            $http({
                method: 'GET',
                headers: {
                },
                url:_API_URL+  urlReq
            }).
            then(function (data, status, headers, config) {
                    if (data.status != 200) {
                        return hasil(data);
                    } else {
                        if (data.data.valid) {
                            return hasil(null, data.data.dt);
                        } else {
                            if (data.data && data.data.login==false){
                                $window.location.reload();
                            }else{
                                return hasil(data.data.err);
                            }
                        }
                    }
                },
                function (data, status, headers, config) {
                    return hasil(data);
                });
        },
        simpanData: function (urlReq, params, callback) {
            $http({
                    async: true,
                    crossDomain: true,
                    method: 'POST',
                    data: $.param(params),
                    headers: {
                    },
                    url: _API_URL + urlReq
                })
                .then(function mySucces(response) {
                    if (response && response.data) {
                        if (response.data.valid) {
                            callback(null, response.data.dt);
                        } else {
                            if (response.data && response.data.login==false){
                                $window.location.reload();
                            }else{
                                callback(response.data);
                            }
                        }
                    }
                }, function myError(response) {
                    if (response && !response.data) {
                        callback({
                            err: 'Error tidak terdeteksi'
                        });
                    } else {
                        callback(response.data);
                    }
                });
        },
        updateData: function (urlReq, params, callback) {
            $http({
                    async: true,
                    crossDomain: true,
                    method: 'PUT',
                    timeout: 5000,
                    headers: {
                    },
                    data: $.param(params),
                    url: _API_URL + urlReq
                })
                .then(function mySucces(response) {
                    if (response) {
                        if (response.data.valid) {
                            callback(null, response.data.dt);
                        } else {
                            if (response.data && response.data.login==false){
                                $window.location.reload();
                            }else{
                                callback(response.data);
                            }
                        }
                    }
                }, function myError(response) {
                    callback(response.data);
                });
        },
        hapusData: function (urlReq, params, callback) {
            $http({
                    async: true,
                    crossDomain: true,
                    method: 'DELETE',
                    timeout: 5000,
                    headers: {
                    },
                    data: $.param(params),
                    url: _API_URL + urlReq
                })
                .then(function mySucces(response) {
                    if (response) {
                        if (response.data.valid) {
                            callback(null, response.data.dt);
                        } else {
                            if (response.data && response.data.login==false){
                                $window.location.reload();
                            }else{
                                callback(response.data);
                            }
                        }
                    }
                }, function myError(response) {
                    console.log('errr', response);
                    callback(response.data);
                });
        },
        cariArrayData: function (nameKey, myArray, field) {
            console.log('cari array', myArray);
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i][field] === nameKey) {
                    return myArray[i];
                }
            }
        },
        approvePr:function(data,callback){
            console.log(data);
            this.simpanData('api/purchase-requisition/' + data + '/approve',{},function(g,s){
                if (g){
                    if (g && g.err && g.err instanceof Array) {
                        console.log('error');
                        $scope.errorMessage = g.err;
                        var elP = '';
                        for (var key in $scope.errorMessage) {
                            elP +=  $scope.errorMessage[key].msg;
                        }
                        console.log(elP)
                       callback(elP);
                    } else {
                        callback(g.err);
                    }
                }else{
                    callback(null,'Approved');
                }
            })
        },
        approvePo:function(data,callback){
            console.log(data);
            this.simpanData('api/po/' + data + '/approve',{},function(g,s){
                if (g){
                    if (g && g.err && g.err instanceof Array) {
                        console.log('error');
                        $scope.errorMessage = g.err;
                        var elP = '';
                        for (var key in $scope.errorMessage) {
                            elP +=  $scope.errorMessage[key].msg;
                        }
                        console.log(elP)
                       callback(elP);
                    } else {
                        callback(g.err);
                    }
                }else{
                    callback(null,'Approved');
                }
            })
        },
        createTree:function(data,group){
            console.log(data);
            console.log(group);
            var grouped =_.mapValues(_.groupBy(data, group), function (clist) {
              return clist.map(function (data) {
                return _.omit(data, group);
              });
            });
            console.log(grouped);
            return grouped;
        },
    };
    return service;
})
