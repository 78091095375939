var app = angular.module('ecourier', [
    'ngAnimate', 'ngAria', 'ngCookies', 'ngMessages', 'ngResource',
    'ngSanitize', 'ngTouch', 'ngStorage', 'ui.router', 'ui.bootstrap',
    'ngMap', 'toaster', 'daterangepicker', 'pascalprecht.translate',
    'ui.grid', 'ui.select', 'ui.grid.pagination', 'ui.grid.exporter',
    'ui.grid.resizeColumns', 'ui.grid.autoResize', 'ngFileUpload', 'ui.utils.masks', 'ja.qr','barcode'
]);
app
    .constant('_SOCKET_URL', urlAddress)
    .constant('_API_URL', '')
    .constant('_APP_VERSION', 'v1.0')
    .constant('_CN_FORM', 'kurirkita')
    .constant('_VAR_GLOBAL', {
        limit: 10
    })
    .constant('_', window._)
    .run(function ($rootScope, $filter, $localStorage, urlServices, globalServices, modalFactory) {
        /** untuk mengantur format date*/
        $rootScope.globalFormatDateRangePickerOption = {
            locale: {
                format: 'DD/MM/YYYY',
                applyClass: 'btn-green',
                fromLabel: "Dari",
                toLabel: "Sampai",
                cancelLabel: 'Batal',
                daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                firstDay: 1,
                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep',
                    'Okt', 'Nov', 'Des'
                ]
            },
            ranges: {
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()]
            }
        };
        $rootScope.globalFormatDateRangePickerSingleTimeOption = {
            singleDatePicker: true,
            locale: {
                format: 'DD/MM/YYYY HH:mm',
                applyClass: 'btn-green',
                fromLabel: "Dari",
                toLabel: "Sampai",
                cancelLabel: 'Batal',
                daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                firstDay: 1,
                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep',
                    'Okt', 'Nov', 'Des'
                ]
            },
            timePicker: true,
            timePicker24Hour: true
        };
        $rootScope.globalFormatDateRangePickerSingle = {
            singleDatePicker: true,
            locale: {
                format: 'DD/MM/YYYY',
                applyClass: 'btn-green',
                fromLabel: "Dari",
                toLabel: "Sampai",
                cancelLabel: 'Batal',
                daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                firstDay: 1,
                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep',
                    'Okt', 'Nov', 'Des'
                ]
            }
        };
        /** end atur format tanggal */
        $rootScope.x_cetak = {
            awbpickup: function (dt) {
                // $state.go('report_awb',{
                //     data:dt.shipment_awbm
                // });
                globalServices.awbpickup(dt);
            },
            cn: function (dt) {

                globalServices.awb(dt);

            },
            tracing: function (dt) {
                modalFactory.open('lg', 'tracing.html', 'modalTracingCtrl', {
                    functionName: 'courierList',
                    params: {
                        params: dt
                    }

                });
            },
            manifest: function (dt) {
                globalServices.manifest(dt);
            }
        };
        $rootScope.minDate = $filter('date')(new Date(), "MM/dd/yyyy");
        $rootScope.dateFormat = "DD-MMM-YYYY";
        $rootScope.globals = {
            nama: userName,
            contact_id: contactId,
            company: company,
            companyHo: companyHo
        };
        $localStorage.globals = $rootScope.globals;
    })
    .config(function ($provide, $qProvider, $httpProvider) {
        $qProvider.errorOnUnhandledRejections(false);
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.headers.common['content-type'] = "application/x-www-form-urlencoded";
        delete $httpProvider.defaults.headers.common['X-Requested-With'];
        var DEFAULT_TIMEZONE = 'GMT+7';
        $provide.decorator('dateFilter', ['$delegate', '$injector', function ($delegate, $injector) {
            var oldDelegate = $delegate;
            var standardDateFilterInterceptor = function (date, format, timezone) {
                if (angular.isUndefined(timezone)) {
                    timezone = DEFAULT_TIMEZONE;
                }
                return oldDelegate.apply(this, [date, format, timezone]);
            };
            return standardDateFilterInterceptor;
        }]);
    })
    .directive('myClock', function ($interval, dateFilter) {
        return {
            restrict: "A",
            transclude: true,
            scope: {
                format: "@"
            },
            link: function (scope, element, attrs) {

                var format = scope.format || 'yyyy/MM/dd HH:mm:ss';

                var updateTime = function () {
                    element.text(dateFilter(new Date(), format));
                };

                // schedule update every second
                var timer = $interval(updateTime, 1000);

                // listen on DOM destroy (removal) event, and cancel the next UI update
                // to prevent updating time after the DOM element was removed.
                element.on('$destroy', function () {
                    $interval.cancel(timer);
                });
            }
        };
    })
    .directive('ngEnter', function () {
        return function (scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                var keyCode = event.which || event.keyCode;
                // If enter key is pressed
                if (keyCode === 13) {
                    scope.$apply(function () {
                        // Evaluate the expression
                        scope.$eval(attrs.ngEnter);
                    });
                    event.preventDefault();
                }
            });
        };
    })
    .directive('uiLoadingspin', function ($rootScope, $http, $anchorScroll) {
        return {
            restrict: 'AC',
            template: '<div class="loading"></div>',
            link: function (scope, el, attrs) {
                el.removeClass('hide').addClass('loading');
                scope.$watch(function () {
                    return $http.pendingRequests.length > 0;
                }, function (hasPending) {
                    if (hasPending) {
                        $anchorScroll();
                        el.removeClass('hide').addClass('loading');
                    } else {
                        el.addClass('hide').removeClass('loading');
                    }
                });

            }
        };
    })
    
    .filter('trustHtml', function ($sce) {
        return function (html) {
            return $sce.trustAsHtml(html)
        }
    })
    .filter('fromNow', function () {
        return function (date) {
            return moment(date).fromNow();
        }
    })
    .filter('propsFilter', function () {
        return function (items, props) {
            var out = [];

            if (angular.isArray(items)) {
                items.forEach(function (item) {
                    var itemMatches = false;

                    var keys = Object.keys(props);
                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    })
    .filter('sumByKey', function () {
        return function (data, key) {
            if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
                return 0;
            }

            var sum = 0;
            for (var i = data.length - 1; i >= 0; i--) {
                sum += parseFloat(data[i][key]);
            }

            return sum;
        };
    })
    .filter('trusted', function ($sce) {
        return function (url) {
            return $sce.trustAsResourceUrl(url);
        };
    })