app
    .controller('prCetakCtrl', function ($rootScope, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL) {
        if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
            delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
        };
        $scope.master={};
        $scope.detail=[];
        console.log(urlServices.getUrlParameter('pr'))
 
            var noPR = urlServices.getUrlParameter('prno');
            if (noPR) {
                urlServices.ambilData('api/purchase-requisition/' + noPR+'/masterdetail', {}, function (e, s) {
                    console.log(s);
                    if (e) {
                        toaster.pop('error', 'Error', e.err);
                    } else {
                        $scope.master=s.master[0];
                        $scope.detail=s.detail;
                        console.log($scope.detail)
                        
                    }
                });
            } else{
                return window.location=_API_URL+'purchasing?screen=prview';
            }
             
        
    });