app.controller('monitoringPurchasingCtrl', function ($rootScope, $scope, $state, $localStorage, urlServices, $filter, toaster, _VAR_GLOBAL, modalFactory, modalService,_API_URL, $window) {
    $scope.filter = '';
    $scope.dataList = {
        totalItems: 0,
        summary:{},
        data: []
    };
    $scope.paginationOptions = {
        pageNumber: 1,
        pageSize: _VAR_GLOBAL.limit,
        sort: null
    };
    $scope.perTglP = {
        startDate: moment().subtract(30, 'days'),
        endDate: moment()
    };
    $scope.viewData = function (pg,qry,excel) {
        $scope.paginationOptions.pageNumber = pg;
        if (excel){
            $scope.getData(10000, $scope.paginationOptions.pageNumber, $scope.filter,qry,excel);
        }else{
            $scope.getData($scope.paginationOptions.pageSize, $scope.paginationOptions.pageNumber, $scope.filter,qry,excel);
        }
    };
    $scope.excelReport=function(){
        $scope.viewData(1,null,true);
    };
    $scope.getData = function (pageSize, page, searchText, qry,excel) {
        var qry = qry||{};
        setTimeout(function () {
            var data;
            if (searchText) {
                var ft = searchText.toLowerCase();
                data = 'term=' + ft + '&limit=' + pageSize + '&pg=' + page + '&start_date=' + $filter('date')($scope.perTglP.startDate._d, "yyyy-MM-dd") + '&end_date=' + $filter('date')($scope.perTglP.endDate._d, "yyyy-MM-dd");


            } else {
                data = 'limit=' + pageSize + '&pg=' + page +   '&start_date=' + $filter('date')($scope.perTglP.startDate._d, "yyyy-MM-dd") + '&end_date=' + $filter('date')($scope.perTglP.endDate._d, "yyyy-MM-dd");
            }
            if (excel){
                data +='&excel=true';
                $window.open(_API_URL+'api/report/monitoring-purchasing?' + data);
            }else{
                urlServices.ambilData('api/report/monitoring-purchasing?' + data, {}, function (err, sukses) {
                    if (err) {
                        toaster.pop('error', 'Error', e);
    
                        $scope.dataList.totalItems = 0;
                        $scope.dataList.data = []
                    } else {
                        $scope.dataList.totalItems = sukses.records;
                        $scope.dataList.data = sukses.data;
                    }
                })
            }    
        }, 100);
    };
    $scope.viewData(1);
})