app.controller('modalListCtrl', function ($rootScope, $scope, urlServices, toaster, $uibModalInstance, _VAR_GLOBAL, params) {
        //console.log('paramtere', params)
        $scope.optionsParams = {};
        $scope.params = params;

        switch ($scope.params.functionName) {
            case 'itemList':
                $scope.optionsParams = {
                    url: 'api/item?',
                    name: 'Daftar Barang',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.itemSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                      </div>              `
                        },
                        {
                            field: 'KodeBarang',
                            name: 'Kode Barang',
                            width: "*"
                        },
                        {
                            field: 'NamaBarang',
                            name: 'Nama Barang',
                            width: "*"
                        },
                        {
                            field: 'Satuan',
                            name: 'Satuan',
                            width: "*"
                        },
                        {
                            field: 'HargaBeli',
                            name: 'Harga',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'itemListPO':
                $scope.optionsParams = {
                    url: 'api/preceive/' + $scope.params.params.noPo + '/item?',
                    name: 'Daftar Barang',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.itemPOSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                      </div>              `
                        },
                        {
                            field: 'KodeBarang',
                            name: 'Kode Barang',
                            width: "*"
                        },
                        {
                            field: 'NamaBarang',
                            name: 'Nama Barang',
                            width: "*"
                        },
                        {
                            field: 'Satuan',
                            name: 'Satuan',
                            width: "*"
                        },
                        {
                            field: 'HargaSatuan',
                            name: 'Harga',
                            width: "*"
                        },
                        {
                            field: 'Qty',
                            name: 'Qty Beli',
                            width: "*"
                        }, {
                            field: 'QtyTerima',
                            name: 'Qty Terima',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'prViewList':

                $scope.optionsParams = {
                    url: 'api/purchase-requisition/' + $scope.params.params.no + '/detail?',
                    name: 'Daftar PR',
                    qry: {

                    },
                    column: [{
                            field: 'KodeBarang',
                            name: 'Kode Barang',
                            width: "*"
                        },
                        {
                            field: 'NamaBarang',
                            name: 'Nama Barang',
                            width: "*"
                        },
                        {
                            field: 'Satuan',
                            name: 'Satuan',
                            width: "*"
                        },
                        {
                            field: 'HargaSatuan',
                            name: 'Harga',
                            width: "*"
                        },
                        {
                            field: 'SubTotal',
                            name: 'Total',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'preViewList':

                $scope.optionsParams = {
                    url: 'api/preceive/' + $scope.params.params.no + '/detail?',
                    name: 'Daftar Penerimaan ',
                    qry: {

                    },
                    column: [{
                            field: 'KodeBarang',
                            name: 'Kode Barang',
                            width: "*"
                        },
                        {
                            field: 'NamaBarang',
                            name: 'Nama Barang',
                            width: "*"
                        },
                        {
                            field: 'Satuan',
                            name: 'Satuan',
                            width: "*"
                        },
                        {
                            field: 'QtyTerima',
                            name: 'Qty',
                            width: "*"
                        },
                        {
                            field: 'HargaSatuan',
                            name: 'Harga',
                            width: "*"
                        },
                        {
                            field: 'SubTotal',
                            name: 'Total',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'poViewList':

                $scope.optionsParams = {
                    url: 'api/po/' + $scope.params.params.no + '/detail?',
                    name: 'Daftar Detail PO',
                    qry: {

                    },
                    column: [{
                            field: 'KodeBarang',
                            name: 'Kode Barang',
                            width: "*"
                        },
                        {
                            field: 'NamaBarang',
                            name: 'Nama Barang',
                            width: "*"
                        },
                        {
                            field: 'Satuan',
                            name: 'Satuan',
                            width: "*"
                        },
                        {
                            field: 'HargaSatuan',
                            name: 'Harga',
                            width: "*"
                        },
                        {
                            field: 'SubTotal',
                            name: 'Total',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'poReceiveList':
                $scope.optionsParams = {
                    url: 'api/preceive/po?',
                    name: 'Daftar PO',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: `<div class="btn-group">
                                <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.poReceiveSelect(row.entity)"><i class="fa fa-archive"></i></button>
                              </div>`
                        },
                        {
                            field: 'NOPO',
                            name: 'PR',
                            width: "*"
                        },
                        {
                            field: 'NoBukti',
                            name: 'Nomor PO',
                            width: "*"
                        },
                        {
                            field: 'Tanggal',
                            name: 'Tanggal',
                            width: "*"
                        },
                        {
                            field: 'Keterangan',
                            name: 'Keterangan',
                            width: "*"
                        },
                        {
                            field: 'Vendor_Name',
                            name: 'Nama Vendor',
                            width: "*"
                        },
                       
                        {
                            field: 'SisaQty',
                            name: 'Telah di terima',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'vendorList':
                $scope.optionsParams = {
                    url: 'api/contact/vendor?filter=GENERAL AFFAIR',
                    name: 'Daftar Vendor',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                    <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.vendorSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                  </div>              `
                        },
                        {
                            field: 'KodeCustomer',
                            name: 'Kode',
                            width: "*"
                        },
                        {
                            field: 'NamaCustomer',
                            name: 'Nama',
                            width: "*"
                        },
                        {
                            field: 'Alamat',
                            name: 'Alamat',
                            width: "*"
                        }
                    ]


                }
                break;
            case 'pr-poList':
                $scope.optionsParams = {
                    url: 'api/po-pr?',
                    name: 'Daftar List PR',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.prpoSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                      </div>              `
                        },
                        {
                            field: 'NoBukti',
                            name: 'Nomor PR',
                            width: "*"
                        },
                        {
                            field: 'Tanggal',
                            name: 'Tanggal',
                            width: "*"
                        },
                        {
                            field: 'Keterangan',
                            name: 'Keterangan',
                            width: "*"
                        },

                        {
                            field: 'TotalQty',
                            name: 'Total Request',
                            width: "*"
                        },
                        {
                            field: 'SisaQty',
                            name: 'PO',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'agenList':
                $scope.optionsParams = {
                    url: 'api/masteragen?',
                    name: 'Daftar Departmen',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.agenSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                      </div>              `
                        },
                        {
                            field: 'KodeUser',
                            name: 'Departement',
                            width: "*"
                        }
                    ]
                }
                break;
            case 'hostList':
                if ($scope.params && $scope.params.params.isFrom) {
                    $scope.isFrom = $scope.params.params.isFrom;
                }
                $scope.optionsParams = {
                    url: 'api/host?',
                    name: 'Host List',
                    qry: {},
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.hostSelect(row.entity)"><i class="fa fa-archive"></i></button>
                            </div>`
                        },
                        {
                            field: 'HostCode',
                            name: 'Host Code',
                            width: "*",
                            resizable: false
                        },
                        {
                            field: 'HostName',
                            name: 'Name',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'cityList':
                console.log('-----city list')
                if ($scope.params && $scope.params.params.isFrom) {
                    $scope.isFrom = $scope.params.params.isFrom;
                }
                $scope.optionsParams = {
                    url: 'api/destination?',
                    name: 'City List',
                    qry: {},
                    column: [{
                            width: "10%",

                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.citySelect(row.entity)"><i class="fa fa-archive"></i></button>
                            </div>`
                        },
                        {
                            field: 'CityCode',
                            name: 'Destination ID',
                            width: "*",
                            resizable: false
                        },
                        {
                            field: 'CityName',
                            name: 'Destination',
                            width: "*"
                        },
                        {
                            field: 'HostCode',
                            name: 'Host ID',
                            width: "*",
                            resizable: false
                        },
                        {
                            field: 'HostName',
                            name: 'Host Name',
                            width: "*"
                        }


                    ]

                }
                break;
            case 'courierList':
                $scope.optionsParams = {
                    url: 'api/mastercourier?',
                    name: 'Courier List',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                                <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.courierSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                              </div>              `
                        },
                        {
                            field: 'CourierCode',
                            name: 'Courier ID',
                            width: "*",
                            resizable: false
                        },
                        {
                            field: 'CourierName',
                            name: 'name',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'customerList':
                if ($scope.params && $scope.params.params.isReceiver) {
                    $scope.isFrom = $scope.params.params.isReceiver;
                }
                $scope.optionsParams = {
                    url: 'api/contact?',
                    name: 'Customer List',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                                            <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.customerSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                                          </div>              `
                        },
                        {
                            field: 'CustomerCode',
                            name: 'Customer Code',
                            width: "*",
                            resizable: false
                        },
                        {
                            field: 'CustomerName',
                            name: 'name',
                            width: "*"
                        },
                        {
                            field: 'Address',
                            name: 'Address',
                            width: "*"
                        },
                        {
                            field: 'BranchID',
                            name: 'Branch',
                            width: "*"
                        },
                        {
                            field: 'MarketingCode',
                            name: 'Marketing',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'baggingDetailList':
                console.log($scope.params)
                if ($scope.params && $scope.params.params.no) {
                    $scope.no = $scope.params.params.no;
                }
                $scope.optionsParams = {
                    url: 'api/bagging/'+$scope.no+'/detail?',
                    name: 'Detail Bag : ' + $scope.no,
                    qry: {

                    },
                    column: [{
                            field: 'AWB',
                            name: 'AWB',
                            width: "*",
                            resizable: false
                        },
                        {
                            field: 'AWB_Date',
                            name: 'Date',
                            width: "*"
                        },  {
                            field: 'Org_Code',
                            name: 'Origin',
                            width: "*"
                        },
                        {
                            field: 'Dest_Host_Code',
                            name: 'Host',
                            width: "*"
                        },
                        {
                            field: 'Dest_Code',
                            name: 'Destination Code',
                            width: "*"
                        },
                        {
                            field: 'Dest_Name',
                            name: 'Destination',
                            width: "*"
                        },
                        {
                            field: 'Shipper_Name',
                            name: 'Shipper',
                            width: "*"
                        },

                        {
                            field: 'Weight',
                            name: 'We',
                            width: "*"
                        },
                        {
                            field: 'Collie',
                            name: 'Koli',
                            width: "*"
                        },

                        {
                            field: 'collie_bagging',
                            name: 'Koli Baging',
                            width: "*"
                        },

                    ]

                }
                break;
            case 'unmanifestHostList':
                if ($scope.params && $scope.params.params.host) {
                    $scope.host = $scope.params.params.host;
                }
                $scope.optionsParams = {
                    url: 'api/bagging/' + $scope.host + '/unmanifest?',
                    name: 'Unmanifest AWB : ' + $scope.host,
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                                                        <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.awbSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                                                      </div>              `
                        },
                        {
                            field: 'AWB',
                            name: 'AWB',
                            width: "*",
                            resizable: false
                        },
                        {
                            field: 'Org_Name',
                            name: 'Origin',
                            width: "*"
                        },
                        {
                            field: 'Dest_Host_Code',
                            name: 'Host',
                            width: "*"
                        },
                        {
                            field: 'Dest_Code',
                            name: 'Destination Code',
                            width: "*"
                        },
                        {
                            field: 'Dest_Name',
                            name: 'Destination',
                            width: "*"
                        },
                        {
                            field: 'Shipper_Name',
                            name: 'Shipper',
                            width: "*"
                        },
                        {
                            field: 'Consign_Name',
                            name: 'Receiver',
                            width: "*"
                        },
                        {
                            field: 'sisa_collie',
                            name: 'collie Baging',
                            width: "*"
                        },
                        {
                            field: 'Weight',
                            name: 'We',
                            width: "*"
                        },
                        {
                            field: 'UOM',
                            name: 'UOM',
                            width: "*"
                        }
                    ]

                }
                break;
            case 'coaList':
                if ($scope.params && $scope.params.params.isFrom) {
                    $scope.isFrom = $scope.params.params.isFrom;
                }
                $scope.optionsParams = {
                    url: 'api/coa/list?',
                    name: 'Daftar Kode Perkiraan',
                    qry: {

                    },
                    column: [{
                            width: "10%",
                            cellClass: "nggrid-action",
                            name: 'Actions',
                            cellTemplate: ` <div class="btn-group">
                                            <button type="button" class="btn btn-sm btn-icon btn-info" ng-click="grid.appScope.coaSelect(row.entity)"><i class="fa fa-archive"></i></button>


                                          </div>              `
                        },
                        {
                            field: 'KodePerkiraan',
                            name: 'Account Code',
                            width: "*"
                        },
                        {
                            field: 'NamaPerkiraan',
                            name: 'Account Name',
                            width: "*"
                        }
                    ]
                }
                break;
            default:
                $scope.close();
                break;
        }
        /** Listening */

        $scope.vendorSelect = function (c) {
            //console.log($scope.isFrom);
            $rootScope.$broadcast('pilih-vendor', {
                vendor: c
            });
            $uibModalInstance.close();
        };

        $scope.itemSelect = function (c) {
            //console.log($scope.isFrom);
            $rootScope.$broadcast('pilih-item', {
                item: c
            });
            $uibModalInstance.close();
        };
        $scope.itemPOSelect = function (c) {
            //console.log($scope.isFrom);
            $rootScope.$broadcast('pilih-item-po', {
                item: c
            });
            $uibModalInstance.close();
        };


        $scope.poReceiveSelect = function (c) {
            //console.log($scope.isFrom);
            $rootScope.$broadcast('pilih-po', {
                po: c
            });
            $uibModalInstance.close();
        };

        $scope.prpoSelect = function (c) {
            console.log(c);
            $rootScope.$broadcast('pilih-pr', {
                pr: c
            });
            $uibModalInstance.close();
        }

        $scope.agenSelect = function (c) {
            $rootScope.$broadcast('pilih-agent', {
                agen: c
            });
            $uibModalInstance.close();
        }
        $scope.citySelect = function (c) {
            //console.log($scope.isFrom);
            $rootScope.$broadcast('pilih-kota', {
                isFrom: $scope.isFrom,
                city: c
            });
            $uibModalInstance.close();
        };

        $scope.courierSelect = function (c) {

            $rootScope.$broadcast('pilih-courier', c);
            $uibModalInstance.close();
        };
        $scope.customerSelect = function (c) {
            if ($scope.isFrom) {
                c.isReceiver = true;
            }
            $rootScope.$broadcast('pilih-contact', c);
            $uibModalInstance.close();
        };
        $scope.hostSelect = function (c) {
            //console.log($scope.isFrom);
            $rootScope.$broadcast('pilih-host', {
                isFrom: $scope.isFrom,
                host: c
            });
            $uibModalInstance.close();
        };

        $scope.awbSelect = function (c) {
            $rootScope.$broadcast('pilih-awb', {

                awb: c
            });
            $uibModalInstance.close();
        }
        $scope.coaSelect = function (c) {
            $rootScope.$broadcast('pilih-coa', {
                isFrom: $scope.isFrom,
                coa: c
            });
            $uibModalInstance.close();
        };
        /** end of listening */

        $scope.filterOptions = {
            filterText: "",
            useExternalFilter: true
        };
        $scope.filterSearch = function () {
            $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');
        }
        $scope.getPagedDataAsync = function (pageSize, page, searchText) {
            setTimeout(function () {
                var data;
                console.log('Search text', searchText);
                if (searchText) {
                    var ft = searchText.toLowerCase();
                    var qry = 'qry=' + JSON.stringify($scope.optionsParams.qry) + '&pg=' + page + '&limit=' + pageSize + '&term=' + ft;
                } else {
                    var qry = 'qry=' + JSON.stringify($scope.optionsParams.qry) + '&pg=' + page + '&limit=' + pageSize;
                }
                console.log($scope.optionsParams.url + qry);
                urlServices.ambilData($scope.optionsParams.url + qry, {}, function (e, d) {
                    console.log('-------', d);
                    if (e) {
                        if (e && e.err && e.err instanceof Array) {
                            $scope.errorMessage = e.err;
                            var elP = '';
                            for (var key in $scope.errorMessage) {
                                elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                            }

                            toaster.pop('error', 'Error Data', elP);

                        } else {

                            toaster.pop('error', 'Error Data', e.err);

                        }
                        $scope.gridOptions.totalItems = 0;
                        $scope.gridOptions.data = []

                    } else {
                        //console.log(d);
                        if (d && d.count) {

                            $scope.gridOptions.totalItems = d.count;
                            $scope.gridOptions.data = d.rows;

                        } else {
                            console.log('herere', d);
                            if (d && !d.total) {
                                $scope.gridOptions.totalItems = d.length;
                                $scope.gridOptions.data = d;
                            } else {

                                $scope.gridOptions.totalItems = d.total;
                                $scope.gridOptions.data = d.dt;
                            }


                        }

                    }
                })
            }, 100);
        };

        var paginationOptions = {
            pageNumber: 1,
            pageSize: _VAR_GLOBAL.limit,
            sort: null
        };


        $scope.gridOptions = {
            paginationPageSizes: [10, 20, 50, 100],
            paginationPageSize: _VAR_GLOBAL.limit,
            enableColumnResizing: true,
            useExternalPagination: true,
            useExternalSorting: true,
            columnDefs: $scope.optionsParams.column,
            onRegisterApi: function (gridApi) {

                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (sortColumns.length == 0) {
                        paginationOptions.sort = null;
                    } else {
                        paginationOptions.sort = sortColumns[0].sort.direction;
                    }
                    $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    paginationOptions.pageNumber = newPage;
                    paginationOptions.pageSize = pageSize;
                    $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');
                });
            }
        };
        $scope.getPagedDataAsync(paginationOptions.pageSize, paginationOptions.pageNumber, $scope.filterOptions.filterText || '');
        $scope.close = function () {
            console.log('batal====')
            $uibModalInstance.close();
        };
    })
    .controller('modalTracingCtrl', function ($rootScope, $scope, urlServices, $uibModalInstance, $filter, toaster, _VAR_GLOBAL, params) {
        console.log('paramtere', params)
        $scope.optionsParams = {
            name: 'Tracing'
        };
        $scope.params = params.params;
        console.log($scope.params);
        urlServices.ambilData('api/outbound/tracing/' + $scope.params.params, {

        }, function (e, d) {
            console.log(d);
            $scope.dtTrace = d;

        });
        $scope.close = function () {
            $uibModalInstance.close();
        };
    });