app.factory('modalFactory', function ($uibModal) {
    return {
        open: function (size, template, modalController, params) {
            return $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: template || 'modal.list.html',
                controller: modalController,
                size: size,
                resolve: {
                    params: function () {
                        return params;
                    }
                }
            });
        }
    };
});
