app.controller('menuInfoCtrl',function($rootScope,$scope,urlServices,toaster,modalService ){
    $scope.menuOpen=true;
    $scope.listMenu=[];
    $scope.dataMenu={};
    $scope.groupMenu=[];
    $scope.listGroup=[];
    $scope.linkMenu=[];
    $scope.editMenu=false;
    $scope.showCategory=false;
    $scope.isAddGroup=true;
    $scope.isEdit=false;
    $scope.selectValue=function(x){
        $scope.dataMenu.module_address=x.nama_menu;
        $scope.dataMenu.module_header=x.header_menu;
        $scope.dataMenu.icon=x.icon;
        // $scope.dataMenu.module_group=x;
    }
    $scope.showAddGroup=function(status){
        $scope.showCategory=true;
        $scope.isAddGroup=status;

    };
    $scope.saveGroup=function(){
        if ($scope.isAddGroup){
            if ($scope.listGroup.indexOf($scope.groupName)<0){
                $scope.showCategory=false;
                $scope.listGroup.push($scope.groupName);
                $scope.dataMenu.module_group=$scope.groupName;
            }else{
                toaster.pop('error','Group Ada','Group sudah ada')
            };

        };
      
        
    };
    $scope.addMenu=function(){
        $scope.dataMenu={};
        $scope.isEdit=false;

    };
    $scope.editMenu=function(x){
        $scope.dataMenu=urlServices.cariArrayData(x.id_module,$scope.listMenu,'id_module');
        $scope.isEdit=true;
    };
    $scope.deleteMenu=function(){
        
        modalService.showModal({}, {
            closeButtonText: 'No',
            actionButtonText: 'Yes',
            headerText: 'Delete menu',
            bodyText: 'Semua menu user yang terhubung dengan ini akan di hapus?'
        })
        .then(function (result) {
            if (result){
                console.log($scope.dataMenu);
                urlServices.hapusData('api/usermenu', $scope.dataMenu, function (g, s) {
                    if (g) {
                       $scope.result = "error";
                       if (g && g.err && g.err instanceof Array) {
                           $scope.errorMessage = g.err;
                           var elP = '';
                           for (var key in $scope.errorMessage) {
                               elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                           }
                           toaster.pop('error','error',elP);
        
                       } else {
                           toaster.pop('error','error',g.err);
        
                       }
                   } else {
                       toaster.pop('success','Hapus','Data berhasil di hapus')
                       $scope.addMenu();
                       $scope.getMenu();
                       
                   }
                });
            }
        })
    };
    $scope.saveMenu=function(){
        if ($scope.isEdit){
            urlServices.updateData('api/usermenu', $scope.dataMenu, function (g, s) {
                if (g) {
                   $scope.result = "error";
                   if (g && g.err && g.err instanceof Array) {
                       $scope.errorMessage = g.err;
                       var elP = '';
                       for (var key in $scope.errorMessage) {
                           elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                       }
                       toaster.pop('error','error',elP);
    
                   } else {
                       toaster.pop('error','error',g.err);
    
                   }
               } else {
                   toaster.pop('success','Success','Data berhasil diupdate')
                   $scope.getMenu();
               }
            });
        }else{
            urlServices.simpanData('api/usermenu', $scope.dataMenu, function (g, s) {
                if (g) {
                   $scope.result = "error";
                   if (g && g.err && g.err instanceof Array) {
                       $scope.errorMessage = g.err;
                       var elP = '';
                       for (var key in $scope.errorMessage) {
                           elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                       }
                       toaster.pop('error','error',elP);
    
                   } else {
                       toaster.pop('error','error',g.err);
    
                   }
               } else {
                   toaster.pop('success','Success','Data berhasil diupdate')
                   $scope.getMenu();
               }
            });
        }
      
    }
    $scope.getMenuLink=function(){
        urlServices.ambilData('api/usermenulink', {
        }, function (e, d) {
            
            if (d) {
                $scope.linkMenu=d;
               
            } else {
                toaster.pop('error', 'Error', 'Error mengambil datas');
            }
            // $scope.dataCustomer=args.dt;
        })
    };

    $scope.getMenu=function(){
        urlServices.ambilData('api/usermenu', {
        }, function (e, d) {
            
            if (d) {
                $scope.listMenu=d;
                $scope.groupMenu = urlServices.createTree(d,'module_group');
                $scope.listGroup=[];
                angular.forEach($scope.groupMenu, function(value, key) {
                    $scope.listGroup.push(key);
                  });
            } else {
                toaster.pop('error', 'Error', 'Error mengambil datas');
            }
            // $scope.dataCustomer=args.dt;
        })
    };

    $scope.getMenu();
    $scope.getMenuLink();

})