'use strict';
app.factory('socket', function ($rootScope, _SOCKET_URL) {
	console.log(_SOCKET_URL);
		var socket = io.connect(_SOCKET_URL);
		return {
			on: function (eventName, callback) {
				socket.on(eventName, function () {
					var args = arguments;
					$rootScope.$apply(function () {
						callback.apply(socket, args);
					});
				});
			},
			emit: function (eventName, data, callback) {
				socket.emit(eventName, data, function () {
					var args = arguments;
					$rootScope.$apply(function () {
						if (callback) {
							callback.apply(socket, args);
						}
					});
				})
			}
		};
    })
    