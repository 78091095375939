app.controller('baggingTrxCtrl', function ($rootScope, $scope, $state, $stateParams, $localStorage, urlServices, $filter, toaster, _VAR_GLOBAL, modalFactory, modalService, socket, $window) {
    console.log('<<<<<<<<<<<<<<<<<<<<<<<< Bagging Controlller >>>>>>>>>>>>>');
    $scope.isReadOnly = false;
    $scope.isEdit = false;
    $scope.defaultData = {};
    $scope.dataManifestHeader = {};
    $scope.dataManifestHeaderDetail=[];
    $scope.dataBagging = [];
    $scope.detailManifest = [];
    $scope.dataCollie={};
    $scope.dataAwb={};
    $scope.dataBagging=[];
    $scope.groupBagging=[];
    $scope.tglBagging=moment()._d;
    $scope.listenBaggingMode="bagging";
    $scope.dataManifestHeader = {
        ManifestDate: moment()._d,
        ModTrans: 'AIR'
    };
    $scope.dataInstruksi = {
        totalItems: 0,
        data: []
    };
    $scope.paginationOptions = {
        pageNumber: 1,
        pageSize: _VAR_GLOBAL.limit,
        sort: null
    };
    $scope.refresh = function () {
        $scope.viewData(1);
    }
    $scope.hapusAwb = function (i) {
        console.log(i);
        $scope.dataInstruksi.data.splice(i, 1);
        return true;
    };
    
    $scope.cariAwb=function(dt){
        console.log(dt);
        if (!$scope.dataManifestHeader.HostCode) {
            return toaster.pop('error', 'Host', 'Isi terlebih dahulu kota hub')
        }
        urlServices.ambilData('api/bagging/' + $scope.dataManifestHeader.HostCode + '/unmanifest/'+dt, {}, function (err, sukses) {
            console.log(err);
            if (err) {
                return toaster.pop('error', 'Error','Data tidak ditemukan');
            } else {
                console.log(sukses);
                if (sukses && sukses.length>0){
                    return $scope.setDataCollie(sukses[0]);
                }else{
                    return toaster.pop('error', 'Error','Data tidak ditemukan');
                }
                
               
            }
        })
    };
    $scope.setDataCollie=function(awb){
        console.log('set data collie',awb);
        $scope.dataManifestHeader.AWB=awb.AWB||null;
        $scope.dataAwb=awb;
        $scope.dataCollie={
            collieActual:awb.Collie,
            collieBagging:awb.sisa_collie||0,
            collieSisa:awb.Collie-(awb.sisa_collie||0),
            inputBagging:awb.Collie-(awb.sisa_collie||0)
        };
    };
    $scope.insertCollie=function(){
        console.log('insert collie',$scope.dataCollie.collieSisa)
        console.log('insert collie',$scope.dataCollie.inputBagging)
        console.log('insert collie',$scope.dataCollie.inputBagging>$scope.dataCollie.collieSisa)
        if ($scope.dataManifestHeader.BagId){
            if ($scope.dataCollie.inputBagging>$scope.dataCollie.collieSisa){
                $scope.dataCollie.inputBagging=$scope.dataCollie.collieSisa;
                return toaster.pop('error','Error','Hanya tersisa ' + $scope.dataCollie.collieSisa);
    
            }else{
                console.log('herere');
                $scope.saveBagging();

            }
        }else{
            return toaster.pop('error','No Bagging', 'Nomor Bagging belum di masukan')
        }
        
    }
    $scope.addNewBagging=function(){
        if (!$scope.dataManifestHeader.HostCode) {
            return toaster.pop('error', 'Host', 'Isi terlebih dahulu kota hub')
        };
        modalService.showModal({}, {
            closeButtonText: 'No',
            actionButtonText: 'Yes',
            headerText: 'Confirm',
            bodyText: 'Tambah Bagging :' + $scope.dataManifestHeader.HostCode + ', tanggal : ' + $scope.dataManifestHeader.ManifestDate
        })
        .then(function (result) {
            if (result){
                urlServices.simpanData('api/baggingnumber', $scope.dataManifestHeader, function (g, s) {
                    console.log(s);
                    if (g) {
                        if (g && g.err && g.err instanceof Array) {
                            $scope.errorMessage = g.err;
                            var elP = '';
                            for (var key in $scope.errorMessage) {
                                elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                            }
                            toaster.pop('error', 'Error Data', elP);
                        } else {
                            toaster.pop('error', 'Error Data', g.err);
                        }
                    } else {
                        toaster.pop('success', 'Success', 'Bagging number ');
                        $scope.listenBaggingMode="bagging"
                        $scope.dataManifestHeader.ManifestID=s.numeric;
                        $scope.dataManifestHeader.BagId=s.numeric;
                        $scope.detailManifest=[];
                    }
                    // $scope.dataCustomer=args.dt;
                })
            }
        })

    };
    $scope.getBaggingNumberData=function(bag){
        console.log('nomor bagging',bag);
        urlServices.ambilData('api/bagging/masterdetail/' + bag, {}, function (e, d) {
            console.log(d)
            if (d) {
                $scope.dataManifestHeader=d.master;
                $scope.detailManifest=d.detail;
            } else {
                toaster.pop('error', 'error data', 'Data tidak bisa di proses' + e.err);

            }
            // $scope.dataCustomer=args.dt;
        })

    };
    $scope.printBaggingSummary=function(dest){
        console.log('tgl----',$scope.dataManifestHeader.ManifestDate);
        if ($scope.dataManifestHeader && $scope.dataManifestHeader.ManifestDate._d){
            $scope.dataManifestHeader.ManifestDate=$scope.dataManifestHeader.ManifestDate._d;
        };
        return $window.location.href = '/manifest-baggingprinthost?moda='+$scope.dataManifestHeader.ModTrans+'&host='+dest + '&start_date=' + $filter('date')($scope.dataManifestHeader.ManifestDate, "yyyy-MM-dd");
    };
    $scope.printBagging=function(bag){
        console.log(bag);
        return $window.location.href = '/manifest-baggingprint?bag='+bag;
    };
    $scope.saveBagging = function () {
        $scope.dataManifestHeader.dataCollie=JSON.stringify($scope.dataCollie);
        modalService.showModal({}, {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                headerText: 'Confirm',
                bodyText: 'Save Bagging :' + $scope.dataManifestHeader.hostname
            })
            .then(function (result) {
                //console.log(result);
                if (result) {
                    urlServices.simpanData('api/bagging', $scope.dataManifestHeader, function (g, s) {

                        if (g) {
                            if (g && g.err && g.err instanceof Array) {
                                $scope.errorMessage = g.err;
                                var elP = '';
                                for (var key in $scope.errorMessage) {
                                    elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                                }

                                toaster.pop('error', 'Error Data', elP);

                            } else {

                                toaster.pop('error', 'Error Data', g.err);

                            }
                        } else {

                            toaster.pop('success', 'Success', 'Data berhasil disimpan');
                              $scope.dataManifestHeader.AWB=null;
                              $scope.dataCollie={};
                              $scope.viewData(1);

                            // $scope.dataManifestHeader = {
                            //     tanggal: moment()._d,
                            //     moda_id: 'A'
                            // };
                        }
                        // $scope.dataCustomer=args.dt;
                    })
                };
            })
    };
    $scope.unConfirm = function (dt, n) {
        var xData = dt;
        console.log('unconform', xData);
        modalService.showModal({}, {
            closeButtonText: 'No',
            actionButtonText: 'Yes',
            headerText: 'Hapus Shipment dari baging',
            bodyText: 'Delete  shipment dari baging '+dt.BagId +'?'
        })
        .then(function (result) {

            //console.log(result);
            if (result) {

                urlServices.simpanData('api/bagging/' + xData.BagId + '/unconfirm/' + xData.AWB, {}, function (e, d) {
                    console.log('unconfirm error', e);
                    console.log('unconfirm success', d);
                    if (d) {
                        $scope.dataManifestHeader.AWB=null;
                        $scope.dataCollie={};
                        $scope.viewData(1);
                    } else {
                        toaster.pop('error', 'error data', 'Data tidak bisa di proses' + e.err);
        
                    }
                    // $scope.dataCustomer=args.dt;
                })
            };
        })

       
    };
    $scope.pilihSemua = function () {
        modalService.showModal({}, {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                headerText: 'Confirm',
                bodyText: 'Delete  All Confirm Bagging?'
            })
            .then(function (result) {

                //console.log(result);
                if (result) {

                    urlServices.ambilData('api/bagging/' + $scope.dataManifestHeader.host + '/unconfirm/all', {}, function (e, d) {
                        if (d) {

                            $scope.viewData(1);

                        } else {
                            toaster.pop('error', 'error data', 'Data tidak bisa di proses' + e.err);

                        }
                        // $scope.dataCustomer=args.dt;
                    })
                };
            })
    };
    $scope.getDataBagging=function(moda){
        console.log('tgl----',$scope.dataManifestHeader.ManifestDate);
        if ($scope.dataManifestHeader && $scope.dataManifestHeader.ManifestDate._d){
            $scope.dataManifestHeader.ManifestDate=$scope.dataManifestHeader.ManifestDate._d;
        };
        var md =moda?moda:'AIR';
        $scope.dataManifestHeader.ModTrans=md;
        urlServices.ambilData('api/bagging/summary?moda='+md+'&start_date=' + $filter('date')($scope.dataManifestHeader.ManifestDate, "yyyy-MM-dd"), {}, function (err, sukses) {
            console.log(err);    
            console.log(sukses);
            if (err) {
                toaster.pop('error', 'Error Load Data Bagging', err.err);
                $scope.dataBagging = [];
                $scope.groupBagging=[];
            } else {
                console.log(sukses);
                $scope.dataBagging = sukses;
                $scope.groupBagging = urlServices.createTree($scope.dataBagging,'HostCode');
                console.log($scope.groupBagging);
                
            }
        })
    };
    $scope.pilihSatu = function (id) {
        var isManifest = $scope.dataInstruksi.data[id].is_onprocess;
        console.log('no string', isManifest)
        if (isManifest) {
            console.log('remove data')
            $scope.unConfirm($scope.dataInstruksi.data[id])
            //     isManifest = false;
        } else {
            //     isManifest = $scope.listData[id].is_manifest;

        }
    };
    $scope.pilihSemua = function () {
        modalService.showModal({}, {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                headerText: 'Confirm',
                bodyText: 'Delete  All Confirm Inbound?'
            })
            .then(function (result) {

                //console.log(result);
                if (result) {

                    urlServices.ambilData('api/bagging/' + $scope.dataManifestHeader.HostCode + '/unconfirm/all', {}, function (e, d) {
                        if (d) {

                            $scope.viewData(1);

                        } else {
                            toaster.pop('error', 'error data', 'Data tidak bisa di proses' + e.err);

                        }
                        // $scope.dataCustomer=args.dt;
                    })
                };
            })
    };
    $scope.viewData = function (pg,moda) {
        console.log($scope.filter);
        $scope.getDataBagging(moda);
        if ($scope.dataManifestHeader.BagId){
            $scope.getBaggingNumberData($scope.dataManifestHeader.BagId);
        }
        
    };
  
    $scope.showListHost = function (isFrom) {
        // //console.log('show list')
        // modalForm.cityList(isFrom);
        modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
            functionName: 'hostList',
            params: {
                isFrom: isFrom
            }
        });
    };
    $scope.globalData = function () {
        urlServices.globalData(null, null, function (e, o) {
            //console.log('Default data', e);
            if (o) {
                $scope.defaultData = o;
                // $scope.dataManifest.contact_id = $scope.defaultData.service ? $scope.defaultData.employee[0].contact_id : null;
                $scope.dataManifestHeader.ModTrans ='AIR'
            } else {
                $scope.defaultData = [];
            }
            return cb(true);
        });
    }
    $scope.showCnListUnmanifest = function () {
        console.log($scope.dataManifestHeader.HostCode);
        if (!$scope.dataManifestHeader.HostCode) {
            return toaster.pop('error', 'Host', 'Isi terlebih dahulu kota hub')
        }
        modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
            functionName: 'unmanifestHostList',
            params: {
                host: $scope.dataManifestHeader.HostCode
            }
        });

    };
    $scope.detailRemove=function(dt){
        console.log(dt);
    }
    $scope.$on('pilih-host', function (event, args) {
        console.log('pilih host', args);
        $scope.dataManifestHeader.HostCode = args.host.HostCode;
        $scope.dataManifestHeader.HostName = args.host.HostName;
        $scope.dataManifestHeader.BagId=null;
        $scope.viewData(1);
        return true;
        // do what you want to do
    });
    $scope.$on('baggingSave', function (event, args) {

        console.log('Bagging Save');
        $scope.viewData(1);
        // do what you want to do
    });
    $scope.$on('pilih-awb', function (event, args) {
        console.log('pilih awb', args);
        
       

        return $scope.setDataCollie(args.awb);
        // do what you want to do
    });

    $scope.$watch('dataManifestHeader.ManifestDate', function (newDate) {
        //;
        $scope.viewData(1);
    }, false);

    socket.on($scope.listenBaggingMode, function (data) {
        console.log('socket data',data);
        
    });

    $scope.viewData(1);


    // init
    // $scope.viewData(1);
    // $scope.globalData();

})