'use strict';

app.service('shareData', function () {
    var data = {};
    return {
        getDataDetails: function () {
            return data;
        },
        setDataDetails: function (detailData) {
            data = detailData;
        }
    };
});
