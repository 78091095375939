
app.controller('managementReportCtrl', function ($rootScope, $scope, $state, urlServices, $filter, toaster,shareData, _VAR_GLOBAL, $uibModal,_API_URL, $window) {

    $scope.showMonth=function(typeTrx){
        $scope.typeTrx=typeTrx;
        shareData.setDataDetails($scope.typeTrx)
        $uibModal.open({
            animation: true,
            keyboard: false,
            backdrop: 'static',
            templateUrl: 'modal.confirm.month.html',
            controller: function ($scope, shareData,$window,urlServices, toaster,$uibModalInstance) {
                $scope.params={
                    start_month:new Date().getMonth()+1,
                    year:new Date().getFullYear()
                };
                $scope.typeTrx=shareData.getDataDetails();
                $scope.close=function(){
                    console.log('close')
                    $uibModalInstance.close();
                }
                $scope.viewReport=function(){
                    console.log($scope.params);
                    if ($scope.params && !$scope.params.start_month || !$scope.params.end_month ){
                        return toaster.pop('error','Bulan','Pilih bulan')
                    };
                    
                    switch($scope.typeTrx) {
                        case 'PL':
                          // code block
                            $window.location.href="/report-income-statement?start_month="+$scope.params.start_month+"&end_month="+$scope.params.end_month+"&year="+$scope.params.year
                          break;
                        case 'BS':
                          // code block
                           $window.location.href="/report-balance-sheet?start_month="+$scope.params.start_month+"&end_month="+$scope.params.end_month+"&year="+$scope.params.year
                          break;
                          case 'BB':
                            // code block
                             $window.location.href="/report-general-ledger?start_month="+$scope.params.start_month+"&end_month="+$scope.params.end_month+"&year="+$scope.params.year
                            break;
                        default:
                          return toaster.pop('error','Report Not Show','Cannot open report')
                      }
                    
                }
                
            }
        });
    };
    $scope.showDate=function(typeTrx){
        $scope.typeTrx=typeTrx;
        shareData.setDataDetails($scope.typeTrx)
        $uibModal.open({
            animation: true,
            keyboard: false,
            backdrop: 'static',
            templateUrl: 'modal.confirm.date.html',
            controller: function ($scope, shareData,$window,$filter,urlServices, toaster,$uibModalInstance,modalFactory) {
                $scope.params={
                    start_date:moment()._d,
                    end_date:moment()._d
                };
                $scope.showListCoa=function(isFrom){
                    modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
                        functionName: 'coaList',
                        params: {
                            isFrom:isFrom||false
                        }
                    });
                }
                
                $scope.typeTrx=shareData.getDataDetails();
                $scope.close=function(){
                    $uibModalInstance.close();
                };
                $scope.$on('pilih-coa', function (event, args) {
                    console.log('pilih host', args);
                    if (args.isFrom){
                        $scope.params.kodePerkiraan1=args.coa.KodePerkiraan
                        $scope.params.kodePerkiraan2=args.coa.KodePerkiraan
                    }else{
                        $scope.params.kodePerkiraan2=args.coa.KodePerkiraan
                    }
                    
                    return true;
                    // do what you want to do
                });
                $scope.viewReport=function(){
                    console.log($scope.params);
                    if ($scope.params && !$scope.params.start_date || !$scope.params.end_date ){
                        return toaster.pop('error','Tanggal','Pilih Tangga')
                    };
                    if ($scope.params && $scope.params.start_date._d){
                        $scope.params.start_date=$scope.params.start_date._d;
                    };
                    if ($scope.params && $scope.params.end_date._d){
                        $scope.params.end_date=$scope.params.end_date._d;
                    };
                    switch($scope.typeTrx) {
                       
                          case 'BB':
                            // code block
                            if ($scope.params.kodePerkiraan1){
                                $window.location.href="/report-general-ledger?start_date="+$filter('date')($scope.params.start_date, "yyyy-MM-dd")+"&end_date="+$filter('date')($scope.params.end_date, "yyyy-MM-dd")+"&from=" + $scope.params.kodePerkiraan1 + "&to=" + $scope.params.kodePerkiraan2
                            }else{
                                $window.location.href="/report-general-ledger?start_date="+$filter('date')($scope.params.start_date, "yyyy-MM-dd")+"&end_date="+$filter('date')($scope.params.end_date, "yyyy-MM-dd")
                            }
                             
                            break;
                        default:
                          return toaster.pop('error','Report Not Show','Cannot open report')
                      }
                    
                };

                
                
            }
        });
    };
    $scope.params={
        start_month:urlServices.getUrlParameter('start_month'),
        end_month:urlServices.getUrlParameter('end_month'),
        year:urlServices.getUrlParameter('year')
    }
    $scope.periods={
        text:"Periode : " + $filter('date')(new Date(parseInt($scope.params.year),parseInt($scope.params.start_month)-1,1), "MMMM") + ' - ' + $filter('date')(new Date(parseInt($scope.params.year),parseInt($scope.params.end_month)-1,1), "MMMM") + ' ' + $scope.params.year,
        isSelect:false
    };
    
})
app.controller('managementReportPlCtrl',function($rootScope, $scope, $state, $location, $filter, urlServices, modalService, modalFactory, toaster, _VAR_GLOBAL,$window){
    
    $scope.params={
        start_month:urlServices.getUrlParameter('start_month'),
        end_month:urlServices.getUrlParameter('end_month'),
        year:urlServices.getUrlParameter('year')
    }
    $scope.periods={
        text:"Periode : " + $filter('date')(new Date(parseInt($scope.params.year),parseInt($scope.params.start_month)-1,1), "MMMM") + ' - ' + $filter('date')(new Date(parseInt($scope.params.year),parseInt($scope.params.end_month)-1,1), "MMMM") + ' ' + $scope.params.year,
        isSelect:false
    };
    console.log($scope.periods);
    $scope.viewData=function(){
        
        urlServices.ambilData('api/report/finance/profitloss?start_month='+$scope.params.start_month+'&end_month='+$scope.params.end_month+'&year='+$scope.params.year, {}, function (g, s) {
            console.log(s);
            if (g) {
                if (g && g.err && g.err instanceof Array) {
                    $scope.errorMessage = g.err;
                    var elP = '';
                    for (var key in $scope.errorMessage) {
                        elP += '<p>' + $scope.errorMessage[key].msg + '</p>';
                    }
                    toaster.pop('error', 'Error Data', elP);
                } else {
                    toaster.pop('error', 'Error Data', g.err);
                }
            } else {
                $scope.dataList=s;
               
            }
            // $scope.dataCustomer=args.dt;
        })
        
    };
    // $scope.viewData();


})