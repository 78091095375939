app.controller('tracingCtrl', function ($rootScope, $scope, $state, $filter, $stateParams, shareData, uuid, urlServices, toaster, globalServices, modalFactory, modalService,modalFactory) {
    
    $scope.tracingSubmit=function(){
        console.log($scope.awb);
        modalFactory.open('lg', 'tracing.html', 'modalTracingCtrl', {
            functionName: 'tracingList',
            params: {
                params: $scope.awb
            }

        });
    }
})