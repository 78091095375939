'use strict';
app.service('modalService',function($uibModal) {
        var modalDefaults = {
            backdrop: true,
            keyboard: true,
            modalFade: true,
            templateUrl: 'modal.confirm.html'
        };
        var modalOptions = {
            isHideFooter: false,
            closeButtonText: 'Close',
            actionButtonText: 'OK',
            headerText: 'Proceed?',
            bodyText: 'Perform this action?',
        
        };

        this.showModal = function(customModalDefaults, customModalOptions) {
            if (!customModalDefaults) customModalDefaults = {};
            customModalDefaults.backdrop = 'static';
            console.log('custom modal defaults', customModalDefaults)
            console.log('custom modal optios', customModalOptions)
            return this.show(customModalDefaults, customModalOptions);
        };

        this.show = function(customModalDefaults, customModalOptions) {
            //Create temp objects to work with since we're in a singleton service
            var tempModalDefaults = {};
            var tempModalOptions = {};

            //Map angular-ui modal custom defaults to modal defaults defined in service
            angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

            //Map modal.html $scope custom properties to defaults defined in service
            angular.extend(tempModalOptions, modalOptions, customModalOptions);

            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = function($scope, $uibModalInstance) {
                    console.log($scope.modalOptions);
                    $scope.modalOptions = tempModalOptions;

                    $scope.modalOptions.ok = function(result) {
                        $uibModalInstance.close(true);
                    };
                    $scope.modalOptions.close = function(result) {
                        $uibModalInstance.dismiss('cancel');
                    };
                };
            }

            return $uibModal.open(tempModalDefaults).result;
        };

});
