app.controller('outboundTrxCtrl', function ($rootScope, $scope, $state, $filter, $stateParams, shareData, uuid, urlServices, toaster, modalFactory, modalService) {
    if ($rootScope.globalFormatDateRangePickerSingleTimeOption && $rootScope.globalFormatDateRangePickerSingleTimeOption.minDate) {
        delete $rootScope.globalFormatDateRangePickerSingleTimeOption["minData"];
    };
    $scope.isReadOnly = false;
    $scope.isEdit = false;
    $scope.isAutoNumber = false;
    $scope.dataOutbound = {};
    $scope.detailItem = [];
    $scope.dataOutbound = {};
    $scope.showListAgen = function () {
        modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
            functionName: 'agenList',
            params: {}
        });
    };
    $scope.showListCustomer = function (isReceiver) {
        modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
            functionName: 'customerList',
            params: {
                isReceiver: isReceiver
            }
        });
    };
    $scope.showListCourier = function () {
        modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
            functionName: 'courierList',
            params: {}
        });
        // modalForm.driverVendor();
    };
    $scope.showListKota = function (isFrom) {
        // //console.log('show list')
        // modalForm.cityList(isFrom);
        modalFactory.open('lg', 'modal.list.html', 'modalListCtrl', {
            functionName: 'cityList',
            params: {
                isFrom: isFrom
            }
        });
    };
    $scope.$on('pilih-agent', function (event, args) {
        $scope.dataOutbound.agen = args.agen.KodeUser;
        console.log(args);
    });
    $scope.$on('pilih-courier', function (event, args) {
        $scope.dataOutbound.pu_code = args.CourierCode;
        console.log(args);
    });
    $scope.$on('pilih-contact', function (event, args) {
        console.log('pilih contact', args);
        if (args && args.isReceiver) {
            $scope.dataOutbound.consign_name = args.CustomerName
            $scope.dataOutbound.consign_attn = args.PU_Name
            //    /$scope.dataOutbound.partner_email=
        } else {
            $scope.dataOutbound.shipper_code = args.CustomerCode;
            $scope.dataOutbound.shipper_name = args.CustomerName;
            $scope.dataOutbound.shipper_address = args.Address;
            $scope.dataOutbound.shipper_contact = args.PU_Name;
            $scope.dataOutbound.shipper_phone = args.Phone;
            $scope.dataOutbound.shipper_fax = args.PU_Fax;
        }
        return true;
        // do what you want to do
    });
    $scope.$on('pilih-kota', function (event, args) {
        //console.log('pilih kota', args);
        if (args && args.isFrom) {
            $scope.dataOutbound.org_host_code = args.city.HostCode;
            $scope.dataOutbound.org_host_name = args.city.HostName;
            $scope.dataOutbound.org_code = args.city.CityCode;
            $scope.dataOutbound.org_name = args.city.CityName;
        } else {
            //console.log('from false', args.city)
            $scope.dataOutbound.dest_host_code = args.city.HostCode;
            $scope.dataOutbound.dest_host_name = args.city.HostName;
            $scope.dataOutbound.dest_code = args.city.CityCode;
            $scope.dataOutbound.dest_name = args.city.CityName;
        }
        return true;
        // do what you want to do
    });
    $scope.getData = function () {
        urlServices.ambilData('api/outbound/defaultdata?', {}, function (e, o) {
            console.log('Default cntact', o);
            $scope.defaultData = o;
        });
    };
    $scope.addOutbound = function () {
        $scope.dataOutbound = {
            awb_date: moment()._d,
            org_code: "JKT",
            org_name: "JAKARTA",
            org_host_code: "JKT",
            org_host_name: "JAKARTA",
            modtrans: "AIR",
            severity: "NORMAL",
            paket: "P"
        };
    };
    $scope.editOutbound = function () {};
    $scope.saveOutbound = function () {
        console.log($scope.dataOutbound);
    }
    if (urlServices.getUrlParameter('isedit') == 'false') {
        $scope.addOutbound();
    } else {
        $scope.dataOutbound.AWB = urlServices.getUrlParameter('cn');
        $scope.editOutbound();
    }
    $scope.addOutbound();
    $scope.getData();
})