app.factory('uuid', function() {
    var svc = {
      'new': function() {
        function _p8(s) {
            console.log(Math.random().toString(16));
          var p = (Math.random().toString(16) + "0000").substr(2, 4);
          return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }
        return _p8() + _p8(true)  + _p8();
      },

      'empty': function() {
        return '0000-0000-0000';
      }
    };

    return svc;
  });
